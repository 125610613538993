import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';

import PropTypes from 'prop-types';

import fetchIntercept from 'fetch-intercept';
import { authed } from '../utils/api';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthState({ children }) {
  const [isAuthed, setIsAuthed] = useState(true);

  useEffect(() => {
    const unregister = fetchIntercept.register({
      // Modify the url or config here
      request: (url, config) => [url, config],

      // Called when an error occurred during another 'request' interceptor call
      requestError: (error) => Promise.reject(error),

      // Modify the reponse object
      response: (response) => {
        if (response.status === 403) {
          setIsAuthed(false);
        }
        return response;
      },

      // Handle an fetch error
      responseError: (error) => Promise.reject(error),
    });

    return () => {
      unregister();
    };
  }, []);

  useEffect(() => {
    authed().then((res) => {
      setIsAuthed(res.authed);
    });
  }, []);

  const providerValue = useMemo(() => ({ isAuthed, setIsAuthed }), [isAuthed, setIsAuthed]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthState.propTypes = { children: PropTypes.node.isRequired };
