import React from 'react';

import PropTypes from 'prop-types';

import {
  IconButton, Icon,
} from 'rsuite';

import styled from 'styled-components';

const ImagePanel = styled.div`
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #e5e5ea;

  & > * {
    padding: 0;
    height: 100%;
    width: 100%;
  }
`;

const PanelBody = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  & button{
    transition: opacity 0.2s;
    opacity: 0;
  }
  &:hover img {
    filter: brightness(80%);
  }
  &:focus-within img {
    filter: brightness(80%);
  }
  &:hover button {
    opacity: 100;
  }
  & button:focus {
    opacity: 100;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 2px;
`;

CloseButton.displayName = 'CloseButton';

export default function CloseableImage({
  className, src, alt, onClose, loading, 'aria-label': ariaLabel,
}) {
  return (
    <ImagePanel className={className}>
      <PanelBody>
        <Image role="presentation" src={src} alt={alt} />
        <CloseButton
          aria-label={ariaLabel}
          loading={loading}
          icon={<Icon icon="close" />}
          circle
          size="xs"
          onClick={onClose}
        />
      </PanelBody>
    </ImagePanel>
  );
}

CloseableImage.defaultProps = {
  onClose: () => {},
  className: null,
  loading: false,
  'aria-label': null,
};

CloseableImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  'aria-label': PropTypes.string,
};
