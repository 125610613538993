import React, { useState } from 'react';

import {
  Alert, List, IconButton, Icon,
} from 'rsuite';

import PropTypes from 'prop-types';

import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { useQuizEdit } from '../states/QuizEditState';

const CenteredGrid = styled.div`
  display: grid;
  place-items: center;
  flex-wrap: wrap;

  grid-template-columns: 14fr 8fr;
  grid-gap: 10px;
  text-align: center;
  padding: 0 10px;

  @media (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    grid-gap: 5px;
    justify-items: start;
  }
`;

const SubGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,auto);
  grid-gap: 10px;
  place-items: center;
`;

const QuestionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-right: 5px;
  text-align: left;
`;

const QuestionTitle = styled.h5`
  color: #575757;
`;

const QuestionDescription = styled.div`
  color: #767676;
  white-space: nowrap;
  @media (max-width: 600px) {
    justify-self: left;
    align-self: flex-start;
  }
`;

const QuestionActions = styled.div`
  width: min-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  justify-self: right;
`;

export default function QuestionListItem({ index, question, quizId }) {
  const { removeQuestion } = useQuizEdit();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const history = useHistory();

  const handleDelete = () => {
    setDeleteLoading(true);
    removeQuestion(question).catch((err) => {
      Alert.error(err.message);
      setDeleteLoading(false);
    });
  };

  const descriptionString = () => {
    const { duration, points, answers } = question;
    const numAnswer = answers.length;
    return `${duration}s / ${points}p / ${numAnswer} answers`;
  };

  return (
    <List.Item index={index}>
      <CenteredGrid>
        <QuestionTitleContainer>
          <Link to={`/dashboard/edit/${quizId}/${question.id}`}>
            <QuestionTitle>{question.question}</QuestionTitle>
          </Link>
        </QuestionTitleContainer>
        <SubGrid>
          <QuestionDescription>{descriptionString()}</QuestionDescription>
          <QuestionActions>
            <IconButton
              aria-label="Edit question"
              circle
              icon={<Icon icon="edit" />}
              onClick={() => history.push(`/dashboard/edit/${quizId}/${question.id}`)}
            />
            <IconButton
              aria-label="Delete question"
              circle
              loading={deleteLoading}
              icon={<Icon icon="trash-o" />}
              onClick={handleDelete}
            />
          </QuestionActions>
        </SubGrid>
      </CenteredGrid>
    </List.Item>
  );
}

QuestionListItem.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        is_correct: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    duration: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
  quizId: PropTypes.string.isRequired,
};
