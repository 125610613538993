import React, { useCallback } from 'react';

import { Button } from 'rsuite';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import Circle from './icons/Circle';
import Diamond from './icons/Diamond';
import Hexagon from './icons/Hexagon';
import Pentagon from './icons/Pentagon';
import Square from './icons/Square';
import Triangle from './icons/Triangle';

const AnswerButtonElement = styled(Button)`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
 
  font-size: calc(0.6rem + 2vmin);
  font-weight: bold;
  white-space: normal;
  text-align: left;

  padding: 10px 1.2em;
`;

const IconContainer = styled.span`
  line-height: 0.8em;
  margin-right:1em;
`;

const VARIANTS = [
  { color: 'red', icon: <Triangle /> },
  { color: 'green', icon: <Square /> },
  { color: 'blue', icon: <Hexagon /> },
  { color: 'orange', icon: <Circle /> },
  { color: 'yellow', icon: <Pentagon /> },
  { color: 'violet', icon: <Diamond /> },
];

export default function AnswerButton({
  answer, variant, onClick, isPressed, isCorrect, showCorrect,
}) {
  const handleClick = useCallback(() => {
    onClick(answer.id);
  }, [answer, onClick]);

  return (
    <AnswerButtonElement
      onClick={handleClick}
      color={isPressed ? null : VARIANTS[variant].color}
      disabled={showCorrect && !isCorrect}
    >
      <IconContainer>{VARIANTS[variant].icon}</IconContainer>
      {answer.answer}
    </AnswerButtonElement>
  );
}

AnswerButton.propTypes = {
  variant: PropTypes.number.isRequired,
  answer: PropTypes.shape({
    answer: PropTypes.string,
    id: PropTypes.string,
    is_correct: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isPressed: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  showCorrect: PropTypes.bool.isRequired,
};
