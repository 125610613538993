import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  margin-right: 0.3em;
`;

const SmallTitle = styled.h3`
  font-weight: normal;
  color: #888;
`;

export default function TitleSubtitle({ title, subtitle }) {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      <SmallTitle>{subtitle}</SmallTitle>
    </TitleContainer>
  );
}

TitleSubtitle.defaultProps = {
  title: null,
  subtitle: null,
};

TitleSubtitle.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
};
