import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import copy from 'copy-to-clipboard';

import {
  Alert, Button, Tag, Tooltip, Whisper,
} from 'rsuite';
import socket from '../socket';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  margin: 20vh 0 3em;
  text-align: center;
`;

const GamePin = styled.h1`
  font-size: 5rem;
`;

const Subtitle = styled.h2`
  font-weight: normal;
`;

const StartButton = styled(Button)`
  margin-top: 0.5em;
`;

const PlayerContainer = styled.div`
  width: 90%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > * {
    margin: 0.25em 0;
    font-size: 1.5em;
  }
`;

const CopyToolTip = <Tooltip>Click to copy link.</Tooltip>;

export default function SessionWaitingPage({ sessionId, players, onStart }) {
  useEffect(() => {
    socket.emit('joinSession', { sessionId });
    return () => {
      socket.emit('leaveSession', { sessionId });
    };
  }, [sessionId]);

  const handleCopy = () => {
    const { origin } = window.location;
    if (copy(new URL(`/join/${sessionId}`, origin))) {
      Alert.success('Copied link to clipboard.', 1000);
    }
  };

  return (
    <Container>
      <Header>
        <Whisper placement="top" trigger="hover" speaker={CopyToolTip}>
          <GamePin onClick={handleCopy}>{sessionId}</GamePin>
        </Whisper>
        <Subtitle>Session Pin</Subtitle>
        <StartButton appearance="primary" onClick={onStart}>
          Start Game
        </StartButton>
      </Header>
      <PlayerContainer>
        {players.length ? (
          players.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
        ) : (
          <div>
            Waiting for players to join...
          </div>
        )}
      </PlayerContainer>
    </Container>
  );
}

SessionWaitingPage.defaultProps = {
  players: [],
  onStart: () => {},
};

SessionWaitingPage.propTypes = {
  sessionId: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })),
  onStart: PropTypes.func,
};
