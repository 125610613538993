/* eslint-disable react/jsx-props-no-spreading */
// Build on top of rsuite example

import React, { useEffect } from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import { Slider, InputNumber } from 'rsuite';

const SliderPadding = styled.div`
  padding: 0 10px;
  flex-grow: 1;
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
`;

const SliderNumberInput = styled(InputNumber)`
  width: 80px !important;
`;

export default function ValueSlider({ id, ...props }) {
  useEffect(() => {
    // RSUITE IS NOT ACCESSIBLE :(
    // Harms performance
    const numberUpButtons = document.querySelectorAll(
      '.rs-input-number-touchspin-up',
    );
    numberUpButtons.forEach((button) => {
      button.setAttribute('aria-label', 'Increase value');
    });

    const numberDownButtons = document.querySelectorAll(
      '.rs-input-number-touchspin-down',
    );
    numberDownButtons.forEach((button) => {
      button.setAttribute('aria-label', 'Decrease value');
    });
  }, []);

  return (
    <SliderContainer>
      <SliderPadding>
        <Slider {...props} />
      </SliderPadding>
      <SliderNumberInput id={id} {...props} />
    </SliderContainer>
  );
}

ValueSlider.defaultProps = {
  id: null,
};

ValueSlider.propTypes = {
  id: PropTypes.string,
};
