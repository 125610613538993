import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageHeader = styled.header`
  font-size: 1.5rem;
  display: flex;
  align-items: baseline;
  padding: 1em 1em;
`;

const Logo = styled(Link)`
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 1em;

  :hover {
    text-decoration: none;
  }
`;

export default function ResultsHeader() {
  return (
    <PageHeader>
      <Logo to="/">BigBrain</Logo>
      <div>Results</div>
    </PageHeader>
  );
}
