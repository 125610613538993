import React from 'react';

import { Link } from 'react-router-dom';

import { Icon, Nav, Navbar } from 'rsuite';

import styled from 'styled-components';

import { useAuth } from '../states/AuthState';
import { logout } from '../utils/api';

const Brand = styled.h1`
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  height: 100%;
`;

export default function DashboardNavbar() {
  const { setIsAuthed } = useAuth();

  const handleLogout = () => {
    logout().then(() => {
      setIsAuthed(false);
    });
  };

  const handleBodySelect = (eventKey) => {
    switch (eventKey) {
      case 'sign-out':
        handleLogout();
        break;

      default:
        break;
    }
  };
  return (
    <Navbar appearance="inverse">
      <Navbar.Header>
        <Nav>
          <Nav.Item componentClass={Link} to={{ pathname: '/dashboard' }}>
            <Brand>BigBrain</Brand>
          </Nav.Item>
        </Nav>
      </Navbar.Header>
      <Navbar.Body>
        <Nav pullRight onSelect={handleBodySelect}>
          <Nav.Item
            aria-label="Sign out"
            eventKey="sign-out"
          >
            <Icon icon="sign-out" style={{ margin: 0 }} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
}
