import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import styled from 'styled-components';

import { Container, Content, Header } from 'rsuite';
import DashboardPage from './pages/DashboardPage';
import QuizEditPage from './pages/QuizEditPage';
import QuestionEditPage from './pages/QuestionEditPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PrivateRoute from './PrivateRoute';
import DashboardNavbar from './components/DashboardNavbar';
import JoinPage from './pages/JoinPage';
import GameState from './GameState';
import GamePage from './pages/GamePage';
import GameResultsPage from './pages/GameResultsPage';
import SessionResultsPage from './pages/SessionResultsPage';
import SessionPage from './pages/SessionPage';
import AuthState from './states/AuthState';
import QuizEditState from './states/QuizEditState';

const FillHeightContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

const DashboardContent = styled(Content)`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export default function App() {
  return (
    <GameState>
      <FillHeightContainer>
        <Router>
          <Switch>
            <Route path="/dashboard">
              <AuthState>
                <Switch>
                  <Route path="/dashboard/login" exact>
                    <LoginPage />
                  </Route>
                  <Route path="/dashboard/register" exact>
                    <RegisterPage />
                  </Route>
                  <Container style={{ height: '100%' }}>
                    <Header>
                      <DashboardNavbar />
                    </Header>
                    <DashboardContent>
                      <QuizEditState>
                        <Switch>
                          <PrivateRoute path="/dashboard/edit/:quizId/:questionId">
                            <QuestionEditPage />
                          </PrivateRoute>
                          <PrivateRoute path="/dashboard/edit/:quizId">
                            <QuizEditPage />
                          </PrivateRoute>
                          <PrivateRoute path="/dashboard/:quizId/session/:sessionId/results">
                            <SessionResultsPage />
                          </PrivateRoute>
                          <PrivateRoute path="/dashboard/:quizId/session/:sessionId">
                            <SessionPage />
                          </PrivateRoute>
                          <PrivateRoute path="/dashboard" exact>
                            <DashboardPage />
                          </PrivateRoute>
                          <Redirect to="/dashboard" />
                        </Switch>
                      </QuizEditState>
                    </DashboardContent>
                  </Container>
                </Switch>
              </AuthState>
            </Route>
            <Route
              path="/game/:sessionid/results/:playerId"
              component={GameResultsPage}
            />
            <Route path="/game/:sessionid">
              <GamePage />
            </Route>
            <Route path="/join/:sessionId" component={JoinPage} />
            <Route path="/" component={JoinPage} exact />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </FillHeightContainer>
    </GameState>
  );
}
