/**
 * Given a js file object representing a jpg or png image, such as one taken
 * from a html file input element, return a promise which resolves to the file
 * data as a data url.
 * More info:
 *   https://developer.mozilla.org/en-US/docs/Web/API/File
 *   https://developer.mozilla.org/en-US/docs/Web/API/FileReader
 *   https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 *
 * Example Usage:
 *   const file = document.querySelector('input[type="file"]').files[0];
 *   console.log(fileToDataUrl(file));
 * @param {File} file The file to be read.
 * @return {Promise<string>} Promise which resolves to the file as a data url.
 */
export function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const valid = validFileTypes.find((type) => type === file.type);
    // Bad data, let's walk away.
    if (!valid) {
      throw new Error('The provided file is not a png, jpg or jpeg image.');
    }

    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });
}

export function jsonFileToObject(file) {
  return new Promise((resolve, reject) => {
    const validFileTypes = ['application/json'];
    const valid = validFileTypes.find((type) => type === file.type);
    // Bad data, let's walk away.
    if (!valid) {
      throw new Error('The provided file is not json file.');
    }

    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(JSON.parse(reader.result));
    reader.readAsText(file);
  });
}

export const questionType = (question) => {
  if (!question) {
    return null;
  }
  return question.type === 'single' ? 'Single' : 'Multiple';
};
