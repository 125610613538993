import styled, { css } from 'styled-components';

const ErrorText = styled.div`
  ${(props) => props.primary
    && css`
      display: none;
    `}
  color: red;
  margin-top: 6px;
`;

export default ErrorText;
