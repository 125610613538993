import React from 'react';

import styled from 'styled-components';

import { Panel, FlexboxGrid } from 'rsuite';

import PropTypes from 'prop-types';

const Card = styled(Panel)`
  width: 300px;
`;

const CardBody = styled(Panel)`
  overflow: inherit;
`;

export default function QuizCardStructure({ thumbnail, header, children }) {
  return (
    <Card shaded bordered bodyFill>
      <FlexboxGrid justify="center" align="middle">
        {thumbnail}
      </FlexboxGrid>
      <CardBody header={header}>{children}</CardBody>
    </Card>
  );
}
QuizCardStructure.defaultProps = {
  thumbnail: null,
  header: null,
  children: null,
};

QuizCardStructure.propTypes = {
  thumbnail: PropTypes.node,
  header: PropTypes.node,
  children: PropTypes.node,
};
