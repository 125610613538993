import React, {
  useState,
  useMemo,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { fetchPlayerAnswer, fetchPlayerQuestion } from './utils/api';

export const GameContext = createContext();
export const GameConsumer = GameContext.Consumer;

export const GAME_STATES = {
  NOT_IN_GAME: 'NOT_IN_GAME',
  WAITING_TO_START: 'WAITING_TO_START',
  ANSWERING_QUESTION: 'ANSWERING_QUESTION',
  WAITING_FOR_QUESTION_RESULT: 'WAITING_FOR_QUESTION_RESULT',
  DISPLAY_QUESTION_RESULT: 'DISPLAY_QUESTION_RESULT',
  DISPLAY_GAME_RESULTS: 'DISPLAY_GAME_RESULTS',
};
Object.freeze(GAME_STATES);

export default function GameState({ children }) {
  const [playerId, setPlayerId] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [stateCode, setStateCode] = useState(GAME_STATES.WAITING_TO_START);
  const [question, setQuestion] = useState(null);

  const handleError = useCallback((errorMessage) => {
    switch (errorMessage) {
      case 'Session has not started yet':
        setStateCode(GAME_STATES.WAITING_TO_START);
        break;
      case 'Player ID does not refer to valid player id':
        setStateCode(GAME_STATES.NOT_IN_GAME);
        break;
      case 'Session ID is not an active session':
        setStateCode(GAME_STATES.DISPLAY_GAME_RESULTS);
        break;
      case 'Question time has not been completed':
        setStateCode(GAME_STATES.ANSWERING_QUESTION);
        break;
      default:
        break;
    }
  }, []);

  const fetchQuestion = useCallback(
    () => fetchPlayerQuestion(playerId)
      .then((json) => {
        if (json.error) {
          handleError(json.error);
        } else if (json.question) {
          const newQuestion = json.question;
          setCorrectAnswers([]);
          setQuestion(newQuestion);
          setStateCode(GAME_STATES.ANSWERING_QUESTION);
        }
      }),
    [handleError, playerId],
  );

  const fetchAnswer = useCallback(
    () => fetchPlayerAnswer(playerId)
      .then((json) => {
        const answers = json.answerIds;
        if (json.error) {
          handleError(json.error);
        } else {
          setStateCode(GAME_STATES.DISPLAY_QUESTION_RESULT);
          if (answers) {
            setCorrectAnswers(answers);
          }
        }
        return answers;
      }),
    [handleError, playerId],
  );

  const providedValue = useMemo(
    () => ({
      playerId,
      setPlayerId,
      stateCode,
      correctAnswers,
      question,
      fetchQuestion,
      fetchAnswer,
      setCorrectAnswers,
    }),
    [
      playerId,
      stateCode,
      correctAnswers,
      question,
      fetchQuestion,
      fetchAnswer,
    ],
  );

  useEffect(() => {
    if (playerId) {
      setStateCode(GAME_STATES.WAITING_TO_START);
    } else {
      setStateCode(GAME_STATES.NOT_IN_GAME);
    }
    setQuestion(null);
  }, [playerId]);

  return (
    <GameContext.Provider value={providedValue}>
      {children}
    </GameContext.Provider>
  );
}

GameState.propTypes = { children: PropTypes.node.isRequired };

export const useGameState = () => {
  const state = useContext(GameContext);
  if (!state) {
    throw new Error('Unable to get state from `GameContext`');
  }
  return state;
};
