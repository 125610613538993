import React from 'react';

import { Loader } from 'rsuite';

import PropTypes from 'prop-types';

import styled from 'styled-components';

const FillHeightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: 4fr 1fr;
  padding: 0 5%;
  text-align: center;
  flex-grow: 1;
`;

const Content = styled.div`
  font-size: 28px;
  line-height: 42px;
  font-weight: bold;
`;

export default function GameLoader({ message }) {
  return (
    <FillHeightContainer>
      <Loader size="lg" vertical content={<Content>{message}</Content>} />
    </FillHeightContainer>
  );
}

GameLoader.defaultProps = {
  message: '',
};

GameLoader.propTypes = {
  message: PropTypes.string,
};
