const { default: styled } = require('styled-components');

const EmptyContainer = styled.div`
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & * {
    font-weight: normal;
  }
`;

export default EmptyContainer;
