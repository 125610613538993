import React, { useState, useEffect } from 'react';

import {
  Form,
  FormGroup,
  Schema,
  ControlLabel,
} from 'rsuite';

import { useHistory, useParams } from 'react-router-dom';

import { useGameState } from '../GameState';
import {
  JoinButton,
  JoinControl,
  Title,
  CreateLink,
  CentredDiv,
} from '../components/JoinPageElements';
import { joinSession } from '../utils/api';

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  sessionId: NumberType('Incorrect PIN Format.')
    .isRequired('Please enter a pin.')
    .isInteger('Incorrect PIN Format.'),
  name: StringType().isRequired('Please enter your name.'),
});

export default function JoinPage() {
  const { sessionId: sessionParam } = useParams();
  const [formValue, setFormValue] = useState({ sessionId: '', name: '' });
  const [formRef, setFormRef] = useState();
  const [sessionError, setSessionError] = useState();
  const history = useHistory();

  const { setPlayerId } = useGameState();

  const handleJoin = () => {
    if (!formRef.check()) {
      return;
    }

    const { sessionId, name } = formValue;
    const trimmedSessionId = sessionId.trim();
    joinSession(trimmedSessionId, name.trim())
      .then((json) => {
        if (json.error) {
          setSessionError(json.error);
        } else {
          setPlayerId(json.playerId);
          history.push(`/game/${trimmedSessionId}`);
        }
      })
      .catch(() => {
        setSessionError('Something went wrong!');
      });
  };

  useEffect(() => {
    setFormValue((prev) => ({ ...prev, sessionId: sessionParam }));
  }, [sessionParam]);

  useEffect(() => {
    document.title = 'Join Session | BigBrain';
  }, []);

  return (
    <CentredDiv>
      <Title>BigBrain</Title>
      <Form
        ref={(ref) => setFormRef(ref)}
        formValue={formValue}
        onSubmit={handleJoin}
        model={model}
        onChange={(fv) => {
          setFormValue(fv);
        }}
      >
        <FormGroup controlId="sessionId-input">
          <ControlLabel srOnly>Session Pin</ControlLabel>
          <JoinControl
            id="sessionId-input"
            name="sessionId"
            placeholder="Session Pin"
            errorMessage={sessionError}
            onChange={() => setSessionError()}
          />
        </FormGroup>
        <FormGroup controlId="name-input">
          <ControlLabel srOnly>Name</ControlLabel>
          <JoinControl
            id="name-input"
            name="name"
            placeholder="Name"
          />
        </FormGroup>
        <FormGroup>
          <JoinButton type="submit" appearance="primary" block>
            Join Game
          </JoinButton>
        </FormGroup>
      </Form>
      <CreateLink to="/dashboard">Create a Game</CreateLink>
    </CentredDiv>
  );
}
