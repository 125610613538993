import React from 'react';
import { IconButton, Icon } from 'rsuite';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonElement = styled(IconButton)`
  position: fixed !important;
  bottom: 10px;
  right: 10px;
  z-index: 999999;
`;

export default function StartGameButton({ onClick }) {
  return (
    <ButtonElement
      icon={<Icon icon="play" />}
      appearance="primary"
      size="lg"
      onClick={onClick}
    >
      Start Game
    </ButtonElement>
  );
}

StartGameButton.defaultProps = {
  onClick: () => {},
};

StartGameButton.propTypes = {
  onClick: PropTypes.func,
};
