import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  FormGroup,
  ControlLabel,
  FormControl,
  Checkbox,
  FlexboxGrid,
  Icon,
  IconButton,
} from 'rsuite';

import styled from 'styled-components';

const AnswerFormGroup = styled(FormGroup)`
  &:last-child {
    margin-bottom: 1em !important;
  }
`;
export default function AnswerFormControl({
  answer, onUpdate, onRemove, placeholder, canRemove,
}) {
  const [answerText, setAnswerText] = useState(answer.answer);
  const [isCorrect, setIsCorrect] = useState(answer.is_correct);

  const handleRemove = () => {
    onRemove(answer);
  };

  const handleTextUpdate = (value) => {
    setAnswerText(value);
  };

  const handleCheckboxUpdate = () => {
    setIsCorrect((old) => !old);
  };

  useEffect(() => {
    if (answerText === answer.answer && isCorrect === answer.is_correct) {
      return;
    }
    onUpdate({ ...answer, answer: answerText, is_correct: isCorrect });
  }, [answer, answerText, isCorrect, onUpdate]);

  return (
    <AnswerFormGroup controlId={`answer-input-${answer.id}`} key={answer.id}>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item style={{ flex: 1 }}>
          <ControlLabel srOnly>Answer</ControlLabel>
          <FormControl
            id={`answer-input-${answer.id}`}
            value={answerText}
            onChange={handleTextUpdate}
            placeholder={placeholder}
            errorMessage={answer.error}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          <FormControl
            accepter={Checkbox}
            checked={isCorrect}
            onChange={handleCheckboxUpdate}
          >
            Correct?
          </FormControl>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item style={{ margin: '0 10px' }}>
          <IconButton
            aria-label="Remove answer"
            icon={<Icon icon="trash-o" />}
            onClick={handleRemove}
            disabled={!canRemove}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </AnswerFormGroup>
  );
}

AnswerFormControl.defaultProps = {
  canRemove: true,
};

AnswerFormControl.propTypes = {
  answer: PropTypes.exact({
    id: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    is_correct: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  canRemove: PropTypes.bool,
};
