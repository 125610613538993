import { BACKEND_URL } from './constants';

/*
              _           _                       _   _
     /\      | |         (_)           /\        | | | |
    /  \   __| |_ __ ___  _ _ __      /  \  _   _| |_| |__
   / /\ \ / _` | '_ ` _ \| | '_ \    / /\ \| | | | __| '_ \
  / ____ \ (_| | | | | | | | | | |  / ____ \ |_| | |_| | | |
 /_/    \_\__,_|_| |_| |_|_|_| |_| /_/    \_\__,_|\__|_| |_|
*/

export function register(email, password, name) {
  return fetch(new URL('/admin/auth/register', BACKEND_URL), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ email, password, name }),
  }).then((res) => res.json());
}

export function login(email, password) {
  return fetch(new URL('/admin/auth/login', BACKEND_URL), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ email, password }),
  }).then((res) => res.json());
}

export function logout() {
  return fetch(new URL('/admin/auth/logout', BACKEND_URL), {
    method: 'POST',
    credentials: 'include',
  }).then((res) => res.json());
}

export function authed() {
  return fetch(new URL('/admin/auth/authed', BACKEND_URL), { credentials: 'include' }).then((r) => r.json());
}

/*
              _           _          ____        _
     /\      | |         (_)        / __ \      (_)
    /  \   __| |_ __ ___  _ _ __   | |  | |_   _ _ ____
   / /\ \ / _` | '_ ` _ \| | '_ \  | |  | | | | | |_  /
  / ____ \ (_| | | | | | | | | | | | |__| | |_| | |/ /
 /_/    \_\__,_|_| |_| |_|_|_| |_|  \___\_\\__,_|_/___|
*/

export function getQuizzes() {
  return fetch(new URL('/admin/quiz', BACKEND_URL), {
    method: 'GET',
    credentials: 'include',
  }).then((res) => res.json());
}

export function getQuiz(quizId) {
  return fetch(new URL(`/admin/quiz/${quizId}`, BACKEND_URL), {
    method: 'GET',
    credentials: 'include',
  }).then((res) => res.json());
}

export function newQuiz(name) {
  return fetch(new URL('admin/quiz/new', BACKEND_URL), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ name }),
  }).then((res) => res.json());
}

export function updateQuiz(quizId, quiz) {
  return fetch(new URL(`/admin/quiz/${quizId}`, BACKEND_URL), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      questions: [],
      name: 'Quiz',
      thumbnail: null,
      ...quiz,
    }),
  }).then((res) => res.json());
}

export function newDetailedQuiz(quiz) {
  return newQuiz(quiz.name).then((json) => {
    if (json.error) {
      return Promise.reject(json.error);
    }
    const { quizId } = json;
    return updateQuiz(quizId, quiz);
  });
}

export function deleteQuiz(quizId) {
  return fetch(new URL(`/admin/quiz/${quizId}`, BACKEND_URL), {
    method: 'DELETE',
    credentials: 'include',
  }).then((res) => res.json());
}

export function startSession(quizId) {
  return fetch(new URL(`/admin/quiz/${quizId}/start`, BACKEND_URL), {
    method: 'POST',
    credentials: 'include',
  }).then((res) => res.json());
}

export function advanceSession(quizId) {
  return fetch(new URL(`/admin/quiz/${quizId}/advance`, BACKEND_URL), {
    method: 'POST',
    credentials: 'include',
  }).then((res) => res.json());
}

export function endSession(quizId) {
  return fetch(new URL(`/admin/quiz/${quizId}/end`, BACKEND_URL), {
    method: 'POST',
    credentials: 'include',
  }).then((res) => res.json());
}

export function fetchSessionStatus(sessionId) {
  return fetch(new URL(`/admin/session/${sessionId}/status`, BACKEND_URL), {
    method: 'GET',
    credentials: 'include',
  }).then((res) => res.json());
}

export function fetchSessionResults(sessionId) {
  return fetch(new URL(`/admin/session/${sessionId}/results`, BACKEND_URL), {
    method: 'GET',
    credentials: 'include',
  }).then((res) => res.json());
}

/*
  _____  _                          _____
 |  __ \| |                        / ____|
 | |__) | | __ _ _   _  ___ _ __  | |  __  __ _ _ __ ___   ___
 |  ___/| |/ _` | | | |/ _ \ '__| | | |_ |/ _` | '_ ` _ \ / _ \
 | |    | | (_| | |_| |  __/ |    | |__| | (_| | | | | | |  __/
 |_|    |_|\__,_|\__, |\___|_|     \_____|\__,_|_| |_| |_|\___|
                  __/ |
                 |___/
*/

export function joinSession(sessionId, name) {
  return fetch(new URL(`/play/join/${sessionId}`, BACKEND_URL), {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ name }),
  }).then((res) => res.json());
}

export function fetchPlayerStatus(playerId) {
  return fetch(new URL(`/play/${playerId}/status`, BACKEND_URL)).then((res) => res.json());
}

export function fetchPlayerQuestion(playerId) {
  return fetch(new URL(`/play/${playerId}/question`, BACKEND_URL)).then((res) => res.json());
}

export function fetchPlayerAnswer(playerId) {
  return fetch(new URL(`/play/${playerId}/answer`, BACKEND_URL)).then((res) => res.json());
}

export function sendPlayerAnswer(playerId, answerIds) {
  return fetch(new URL(`/play/${playerId}/answer`, BACKEND_URL), {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ answerIds }),
  }).then((res) => res.json());
}

export function fetchPlayerResults(playerId) {
  return fetch(new URL(`/play/${playerId}/results`, BACKEND_URL)).then((res) => res.json());
}
