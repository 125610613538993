import React from 'react';

import { Dropdown, IconButton, Icon } from 'rsuite';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import Joystick from '../icons/Joystick';
import ThreeDots from '../icons/ThreeDots';

const StyledDropdown = styled(Dropdown)`
  margin-left: auto;

  & svg {
    margin-right: 5px;
  }
`;

const StyledButton = styled(IconButton)`
  display: grid;
  place-items: center;
  font-size: 20px;
`;

export default function QuizCardOptions({
  loading, onExport, onDelete, onStart, onStop, sessionActive,

}) {
  return (
    <StyledDropdown
      renderTitle={() => (
        <StyledButton
          aria-label="Quiz Options"
          loading={loading}
          appearance="subtle"
          icon={<ThreeDots />}
          circle
          size="sm"
        />
      )}
      disabled={loading}
      placement="leftEnd"
    >
      <Dropdown.Item
        onSelect={onStart}
        role="menuitem"
        aria-label={sessionActive ? 'View Session' : 'Start Session'}
      >
        <Joystick />
        {' '}
        {sessionActive ? 'View Session' : 'Start Session'}
      </Dropdown.Item>
      {sessionActive
      && (
      <Dropdown.Item
        onSelect={onStop}
        role="menuitem"
        aria-label="Stop Session"
      >
        <Icon icon="stop" />
        {' '}
        Stop Session
      </Dropdown.Item>
      ) }
      <Dropdown.Item
        aria-label="Export Quiz"
        onSelect={onExport}
        role="menuitem"
      >
        <Icon icon="export" />
        {' '}
        Export Quiz
      </Dropdown.Item>
      <Dropdown.Item
        aria-label="Delete Quiz"
        onSelect={onDelete}
        role="menuitem"
      >
        <Icon icon="trash" />
        {' '}
        Delete Quiz
      </Dropdown.Item>
    </StyledDropdown>
  );
}

QuizCardOptions.defaultProps = {
  onStart: () => {},
  onStop: () => {},
  onExport: () => {},
  onDelete: () => {},
  loading: false,
  sessionActive: false,
};

QuizCardOptions.propTypes = {
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  onExport: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  sessionActive: PropTypes.bool,
};
