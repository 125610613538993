import React from 'react';
import PropTypes from 'prop-types';

import { Icon, IconButton, List } from 'rsuite';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionListItem from './QuestionListItem';
import { useQuizEdit } from '../states/QuizEditState';

const Container = styled.div`
  margin: 2em 0;
`;

const ListElement = styled(List)`
  margin: 1em 0;
`;

const QuestionListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionListTitle = styled.h4`
  font-size: 28px;
  line-height: 42px;
`;

const EmptyListItem = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5em;
  padding-bottom: 1em;
`;

export default function QuestionList({ questions, quizId }) {
  const { setQuestions, quiz } = useQuizEdit();
  const history = useHistory();

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const moveData = quiz.questions.splice(oldIndex, 1);
    const newData = [...quiz.questions];
    newData.splice(newIndex, 0, moveData[0]);
    setQuestions(newData);
  };

  return (
    <Container>
      <QuestionListHeader>
        <QuestionListTitle>Questions</QuestionListTitle>
        <IconButton
          size="sm"
          onClick={() => history.push(`/dashboard/edit/${quizId}/${uuidv4()}`)}
          icon={<Icon icon="plus" />}
          appearance="primary"
        >
          New Question
        </IconButton>
      </QuestionListHeader>
      <ListElement sortable onSort={handleSortEnd}>
        {questions && questions.length ? (
          questions.map((question, index) => (
            <QuestionListItem
              key={question.id}
              index={index}
              question={question}
              quizId={quizId}
            />
          ))
        ) : (
          <EmptyListItem>
            <p>This quiz has no questions.</p>
            <Link to={`/dashboard/edit/${quizId}/${uuidv4()}`}>
              Create one.
            </Link>
          </EmptyListItem>
        )}
      </ListElement>
    </Container>
  );
}

QuestionList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string.isRequired,
          is_correct: PropTypes.bool.isRequired,
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      duration: PropTypes.number.isRequired,
      points: PropTypes.number.isRequired,
    }),
  ).isRequired,
  quizId: PropTypes.string.isRequired,
};
