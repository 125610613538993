import React, { useEffect, useState, useCallback } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
  Schema,
  HelpBlock,
  Uploader,
} from 'rsuite';

import AnswerFormControl from '../components/AnswerFormControl';
import BreadcrumbLink from '../components/BreadcrumbLink';
import CloseableImage from '../components/CloseableImage';
import ErrorText from '../components/ErrorText';
import GameLoader from '../components/GameLoader';
import PageContainer from '../components/PageContainer';
import StyledBreadcrumb from '../components/StyledBreadcrumb';
import ValueSlider from '../components/ValueSlider';
import { useQuizEdit } from '../states/QuizEditState';
import { fileToDataUrl } from '../utils/helpers';

const { StringType, NumberType } = Schema.Types;

const ImageUploadContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

const FileListImage = styled(CloseableImage)`
  height: 50px;
  width: 50px;
  margin-left: 1em;
`;

const AnswerErrorText = styled(ErrorText)`
  margin-bottom: 2em;
`;

const model = Schema.Model({
  question: StringType().isRequired('What is your question?'),
  type: StringType().isRequired('Pick one.'),
  image: null,
  video: null,
  duration: NumberType().isInteger().isRequired("What's the duration?"),
  points: NumberType().isInteger().isRequired('How much is it worth?'),
});

const newAnswer = () => ({
  answer: '',
  is_correct: false,
  id: uuidv4(),
});

export default function QuestionEditPage() {
  const { questionId, quizId } = useParams();
  const { quiz, fetchQuiz, addQuestion } = useQuizEdit();
  const [answers, setAnswers] = useState([newAnswer(), newAnswer()]);
  const [answerError, setAnswerError] = useState(null);
  const [title, setTitle] = useState('Edit Question');
  const [imageMedia, setImageMedia] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [formValue, setFormValue] = useState({
    id: questionId,
    question: '',
    answers: [],
    type: '',
    image: null,
    video: null,
    duration: 0,
    points: 0,
  });
  const [form, setFormRef] = useState(null);

  const checkAnswers = useCallback(
    (ansArray) => {
      if (!ansArray) return false;
      let error = '';
      let result = true;
      if (!ansArray.some((answer) => answer.is_correct)) {
        error = 'At least one answer must be correct.';
        result = false;
      }
      const correctCount = ansArray.reduce(
        (acu, cur) => acu + (cur.is_correct ? 1 : 0),
        0,
      );
      if (correctCount > 1 && formValue.type === 'single') {
        error = 'You selected Single Answer, but provided multiple correct answers.';
        result = false;
      }

      const checkedAnswers = ansArray.map((answer) => {
        const checkedAnswer = { ...answer };
        if (!checkedAnswer.answer.trim().length) {
          checkedAnswer.error = 'Answer is missing.';
          result = false;
        } else {
          delete checkedAnswer.error;
        }
        return checkedAnswer;
      });

      setAnswers(checkedAnswers);
      setAnswerError(error);
      return result;
    }, [formValue],
  );

  const handleSubmit = () => {
    if (!(form.check() && checkAnswers(answers))) {
      return;
    }
    const newQuestionObj = {
      ...formValue,
      answers,
      image: imageMedia,
    };
    addQuestion(newQuestionObj).then(history.push(`/dashboard/edit/${quizId}`));
  };

  const handleAddAnswer = () => {
    if (answers.length < 6) {
      setAnswers([...answers, newAnswer()]);
    }
  };

  const removeAnswer = (answer) => {
    if (answers.length > 2) {
      checkAnswers(answers.filter((a) => a.id !== answer.id));
    }
  };

  const updateAnswer = (answer) => {
    checkAnswers(answers.map((a) => (a.id === answer.id ? answer : a)));
  };

  const handleMediaChange = (fileList) => {
    const fileData = fileList.pop();
    if (fileData) {
      fileToDataUrl(fileData.blobFile).then((base64) => {
        setImageMedia(base64);
      });
    }
  };

  const handleMediaRemove = () => {
    setImageMedia(null);
  };

  useEffect(() => {
    if (!quiz) {
      setIsLoading(true);
      fetchQuiz(quizId).then((res) => {
        if (res.error) {
          history.push('/dashboard');
        } else {
          setIsLoading(false);
        }
      });
    }
  }, [fetchQuiz, history, quiz, quizId]);

  useEffect(() => {
    if (!quiz || !quiz.questions) {
      return;
    }
    const question = quiz.questions.find((q) => q.id === questionId);
    if (question) {
      setTitle('Edit Question');
      setFormValue(question);
      setAnswers(question.answers);
      setImageMedia(question.image);
    } else {
      setTitle('New Question');
    }
  }, [questionId, quiz]);

  useEffect(() => {
    document.title = 'Edit Question | BigBrain';
  }, [quiz]);

  if (isLoading || !quiz) {
    return <GameLoader />;
  }

  return (
    <PageContainer>
      <StyledBreadcrumb>
        <BreadcrumbLink to="/dashboard">Dashboard</BreadcrumbLink>
        <BreadcrumbLink to={`/dashboard/edit/${quizId}`}>
          {quiz.name}
        </BreadcrumbLink>
        <BreadcrumbLink active>{title}</BreadcrumbLink>
      </StyledBreadcrumb>
      <h2>{title}</h2>
      <Form
        ref={(ref) => setFormRef(ref)}
        checkTrigger="submit"
        fluid
        model={model}
        formValue={formValue}
        onChange={(newValue) => setFormValue(newValue)}
        onSubmit={handleSubmit}
        onCheck={() => checkAnswers(answers)}
      >
        <FormGroup controlId="type-input">
          <ControlLabel>What kind of Question?</ControlLabel>
          <FormControl id="type-input" name="type" accepter={RadioGroup} inline>
            <Radio value="single">Single Answer</Radio>
            <Radio value="multiple">Multiple Answers</Radio>
          </FormControl>
        </FormGroup>
        <FormGroup controlId="question-input">
          <ControlLabel>What&apos;s the Question?</ControlLabel>
          <FormControl id="question-input" name="question" />
        </FormGroup>
        <FormGroup controlId="duration-input">
          <ControlLabel>Time limit</ControlLabel>
          <FormControl
            id="duration-input"
            name="duration"
            accepter={ValueSlider}
            min={5}
            max={60}
            progress
          />
        </FormGroup>
        <FormGroup controlId="points-input">
          <ControlLabel>What&apos;s it worth?</ControlLabel>
          <FormControl
            id="points-input"
            name="points"
            accepter={ValueSlider}
            defaultValue={1000}
            min={0}
            max={2000}
            step={100}
            progress
            graduated
          />
        </FormGroup>
        <FormGroup controlId="youtube-input">
          <ControlLabel>Optional YouTube link</ControlLabel>
          <FormControl id="youtube-input" name="video" />
        </FormGroup>
        <FormGroup controlId="image-input">
          <ControlLabel>Optional image</ControlLabel>
          <ImageUploadContainer>
            <Uploader
              id="image-input"
              accept="image/jpeg, image/png"
              fileListVisible={false}
              onChange={handleMediaChange}
              autoUpload={false}
            >
              <Button>{imageMedia ? 'Change' : 'Upload'}</Button>
            </Uploader>
            {imageMedia && (
              <FileListImage
                src={imageMedia}
                alt="Uploaded image media"
                onClose={handleMediaRemove}
                aria-label="Remove question media"
              />
            )}
          </ImageUploadContainer>
          <HelpBlock>
            This image will not be displayed if there is a YouTube video.
          </HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>What are the Answers?</ControlLabel>
          <div>
            {answers.map((answer, index) => (
              <AnswerFormControl
                key={answer.id}
                answer={answer}
                placeholder={`Answer ${index + 1}`}
                onRemove={removeAnswer}
                onUpdate={updateAnswer}
                canRemove={answers.length > 2}
              />
            ))}
          </div>
          <AnswerErrorText hide={!answerError}>{answerError}</AnswerErrorText>
          <Button disabled={answers.length >= 6} onClick={handleAddAnswer}>+ Answer</Button>
        </FormGroup>
        <FormGroup>
          <Button
            appearance="primary"
            type="submit"
            style={{ marginRight: '1em' }}
          >
            Submit
          </Button>
          <Link to={`/dashboard/edit/${quizId}`}>
            <Button>Cancel</Button>
          </Link>
        </FormGroup>
      </Form>
    </PageContainer>
  );
}
