import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import {
  Form, FormGroup, ControlLabel, FormControl, Button, Schema, Alert,
} from 'rsuite';

import AuthButtonRow from '../components/AuthButtonRow';
import AuthPanel from '../components/AuthPanel';
import { useAuth } from '../states/AuthState';
import { register } from '../utils/api';

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired('Please enter your name.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('Please enter your email.'),
  password: StringType()
    .minLength(6, 'Please enter a password longer than 6 characters.')
    .isRequired('Please enter a password.'),
});

export default function RegisterPage() {
  const history = useHistory();
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [form, setFormRef] = useState(null);
  const { setIsAuthed } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, []);

  useEffect(() => {
    document.title = 'Register | BigBrain';
  }, []);

  const handleSubmit = () => {
    if (!form.check()) {
      return;
    }

    register(formValue.email, formValue.password, formValue.name)
      .then((json) => {
        if (json.error) {
          setErrorMessage(json.error);
        } else {
          setIsAuthed(true);
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  };

  const handleCheck = (e) => {
    setErrorMessage(e.email);
  };

  return (
    <AuthPanel header={<h3>Register</h3>}>
      <Form
        fluid
        model={model}
        checkTrigger="blur"
        formValue={formValue}
        onChange={(newValue) => setFormValue(newValue)}
        onSubmit={handleSubmit}
        onCheck={handleCheck}
        ref={(ref) => setFormRef(ref)}
      >
        <FormGroup controlId="name-input">
          <ControlLabel>Name</ControlLabel>
          <FormControl id="name-input" name="name" />
        </FormGroup>
        <FormGroup controlId="password-input">
          <ControlLabel>Email</ControlLabel>
          <FormControl id="email-input" name="email" errorMessage={errorMessage} />
        </FormGroup>
        <FormGroup id="password-input">
          <ControlLabel>Password</ControlLabel>
          <FormControl id="password-input" name="password" type="password" />
        </FormGroup>
        <FormGroup>
          <AuthButtonRow>
            <Button appearance="primary" type="submit">
              Sign up
            </Button>
            <Link to="/dashboard/login">Already have an account?</Link>
          </AuthButtonRow>
        </FormGroup>
      </Form>
    </AuthPanel>
  );
}
