import React, { useState, useCallback } from 'react';

import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  Button,
  ControlLabel,
  HelpBlock,
  Uploader,
  Schema,
  Alert,
} from 'rsuite';

import PropTypes from 'prop-types';

import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import { newDetailedQuiz } from '../utils/api';
import { NO_IMAGE } from '../utils/constants';
import { fileToDataUrl, jsonFileToObject } from '../utils/helpers';
import { quizValidator } from '../utils/validators';

import FileListItem from './FileListItem';
import FluidModal from './FluidModal';

const UploadArea = styled.button`
  width: 100%;
  line-height: 200px !important;
`;

const InlineHelpBlock = styled(HelpBlock)`
  margin-top: 0;
`;

const CustomModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired('Please enter an title.'),
});

const emptyForm = {
  name: '',
};
export default function NewGameModal({ show, onHide, onCreate }) {
  const [formValue, setFormValue] = useState(emptyForm);
  const [form, setFormRef] = useState(null);
  const [thumbnailList, setThumbnailList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);

  const [importList, setImportList] = useState([]);

  const handleSubmit = useCallback(() => {
    if (!form.check()) {
      return;
    }

    setSubmitLoading(true);

    newDetailedQuiz(formValue)
      .then((json) => {
        if (json.error) {
          throw new Error();
        }
        return onCreate();
      })
      .catch(() => {
        Alert.error('Something went wrong');
      })
      .then(() => {
        setSubmitLoading(false);
        onHide();
      });
  }, [form, formValue, onCreate, onHide]);

  const handleShow = useCallback(() => {
    setFormValue(emptyForm);
    setThumbnailList([]);
    setSelectedThumbnail(null);
    setSubmitLoading(false);
    setImportLoading(false);
  }, []);

  const handleShown = useCallback(() => {
    document.getElementById('name-input').focus(); // rsuite does not have focus API
  }, []);

  const replaceOldFile = (newList) => (oldList) => newList.filter(
    (file) => !oldList.find((f) => f.fileKey === file.fileKey),
  );

  const handleThumbnailChange = useCallback(
    (value) => {
      const newList = replaceOldFile(value)(thumbnailList);
      setThumbnailList(newList);

      if (newList.length) {
        const compressOptions = {
          maxSizeMB: 2,
          useWebWorker: true,
          maxWidthOrHeight: 300,
        };
        imageCompression(newList[0].blobFile, compressOptions)
          .then((compressedBlob) => fileToDataUrl(compressedBlob))
          .then((thumbnail) => {
            setFormValue((oldValue) => ({ ...oldValue, thumbnail }));
            setSelectedThumbnail({ src: thumbnail, name: newList[0].name });
          });
      }
    },
    [thumbnailList],
  );

  const handleImportChange = useCallback(
    (value) => {
      const newList = replaceOldFile(value)(importList);
      setImportList(newList);
      if (!newList.length) {
        return;
      }
      setImportLoading(true);
      const file = newList[0].blobFile;
      jsonFileToObject(file).then((quiz) => {
        if (quizValidator(quiz)) {
          setFormValue(quiz);
          if (quiz.thumbnail && quiz.thumbnail !== NO_IMAGE) {
            setSelectedThumbnail({
              src: quiz.thumbnail,
              name: 'Imported Thumbnail',
            });
          }
        } else {
          Alert.error('The file provided is an invalid quiz', 3000);
        }
        setImportLoading(false);
      });
    },
    [importList],
  );

  const handleFormChange = useCallback((newValue) => {
    setFormValue((oldValue) => ({ ...oldValue, ...newValue }));
  }, []);

  return (
    <FluidModal
      show={show}
      onHide={onHide}
      size="md"
      onEnter={handleShow}
      onEntered={handleShown}
      backdrop={submitLoading ? 'static' : true}
    >
      <Modal.Header>
        <Modal.Title>New Game</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={model}
          checkTrigger="blur"
          formValue={formValue}
          onChange={handleFormChange}
          onSubmit={handleSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <FormGroup>
            <ControlLabel>Quiz Title</ControlLabel>
            <FormControl
              id="name-input"
              name="name"
              placeholder="What's it going to be called?"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Thumbnail
              <InlineHelpBlock tooltip>Optional</InlineHelpBlock>
            </ControlLabel>
            <Uploader
              listType="picture-text"
              draggable
              accept="image/jpeg, image/png"
              fileList={thumbnailList}
              fileListVisible={false}
              onChange={handleThumbnailChange}
              autoUpload={false}
            >
              <UploadArea type="button">
                Click or Drag file into this area to upload
              </UploadArea>
            </Uploader>
            {selectedThumbnail && (
              <FileListItem
                thumbnail={selectedThumbnail.src}
                name={selectedThumbnail.name}
                onClose={() => setSelectedThumbnail(null)}
              />
            )}
          </FormGroup>
        </Form>
      </Modal.Body>
      <CustomModalFooter>
        <Uploader
          autoUpload={false}
          fileListVisible={false}
          fileList={importList}
          onChange={handleImportChange}
          accept="application/json"
          disabled={submitLoading}
        >
          <Button loading={importLoading}>Import a quiz</Button>
        </Uploader>
        <div>
          <Button
            appearance="default"
            onClick={onHide}
            disabled={submitLoading}
          >
            Never Mind
          </Button>
          <Button
            appearance="primary"
            type="submit"
            onClick={handleSubmit}
            loading={submitLoading}
          >
            Create
          </Button>
        </div>
      </CustomModalFooter>
    </FluidModal>
  );
}

NewGameModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};
