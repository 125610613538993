import React, { useEffect, useState, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Alert } from 'rsuite';

import styled from 'styled-components';

import BreadcrumbLink from '../components/BreadcrumbLink';
import CorrectRateGraph from '../components/CorrectRateGraph';
import EmptyContainer from '../components/EmptyContainer';
import GameLoader from '../components/GameLoader';
import PageContainer from '../components/PageContainer';
import QuizAnswerPieCharts from '../components/QuizAnswerPieCharts';
import ResponseTimeGraph from '../components/ResponseTimeGraph';
import StyledBreadcrumb from '../components/StyledBreadcrumb';
import TitleSubtitle from '../components/TitleSubtitle';
import TopPlayersTable from '../components/TopPlayersTable';
import { fetchSessionResults, getQuiz } from '../utils/api';

const summariseResults = (results, quiz) => results.map((player) => {
  const numAnswers = player.answers.length;

  const filled = [...Array(quiz.questions.length)].map((_, index) => {
    const answered = player.answers.find((a) => a.position === index);
    return answered || {
      position: index,
      answerIds: [],
      correct: false,
      answeredAt: null,
      questionStartedAt: null,
    };
  });

  const cleanedAnswers = filled.map((answer) => {
    const { questionStartedAt, answeredAt, position } = answer;
    const answerTime = answeredAt
      ? (Date.parse(answeredAt) - Date.parse(questionStartedAt)) / 1000
      : 0;
    const score = answeredAt && answer.correct
      ? (1 - answerTime / quiz.questions[position].duration) * quiz.questions[position].points : 0;
    return {
      ...answer,
      number: position,
      answerTime,
      score,
    };
  });

  const totalScore = cleanedAnswers.reduce(
    (acu, cur) => (acu + cur.score),
    0,
  );
  const totalTime = cleanedAnswers.reduce(
    (acu, cur) => acu + cur.answerTime,
    0,
  );
  const numCorrect = cleanedAnswers.reduce(
    (acu, cur) => acu + (cur.correct ? 1 : 0),
    0,
  );

  return {
    ...player,
    answers: cleanedAnswers,
    totalScore,
    avgScore: numAnswers ? (totalScore / numAnswers) : 0,
    totalTime,
    avgTime: numAnswers ? (totalTime / numAnswers) : 0,
    numCorrect,
  };
});

const DataSection = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  & > * {
    margin: 3em 0;
    max-width: 700px;
  }
`;

export default function SessionResultsPage() {
  const { quizId, sessionId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    const [quizData, resultsResponse] = await Promise.all([
      getQuiz(quizId),
      fetchSessionResults(sessionId),
    ]);

    if (quizData.error || resultsResponse.error) {
      throw new Error('Could not get session results.');
    }

    setResults(summariseResults(resultsResponse.results, quizData));
    setQuiz({
      ...quizData,
      questions: quizData.questions.map((question, index) => ({
        ...question,
        number: index + 1,
      })),
    });
  }, [quizId, sessionId]);

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .catch((err) => {
        Alert.error(err.message, 5000);
        history.replace('/dashboard');
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [fetchData, history]);

  useEffect(() => {
    document.title = 'Results | BigBrain';
  }, []);

  if (isLoading || !results) {
    return <GameLoader />;
  }

  const EmptyResults = () => (
    <EmptyContainer>
      <h3>No Results Available</h3>
    </EmptyContainer>
  );

  return (
    <PageContainer>
      <StyledBreadcrumb>
        <BreadcrumbLink to="/dashboard">Dashboard</BreadcrumbLink>
        <BreadcrumbLink to={`/dashboard/edit/${quizId}`}>
          {quiz.name}
        </BreadcrumbLink>
        <BreadcrumbLink active>Results</BreadcrumbLink>
      </StyledBreadcrumb>
      <TitleSubtitle title="Results" subtitle={quiz.name} />
      {results && results.length ? (
        <DataSection>
          <TopPlayersTable results={results} numPlayers={5} />
          <ResponseTimeGraph results={results} />
          <CorrectRateGraph results={results} />
          <QuizAnswerPieCharts quiz={quiz} results={results} />
        </DataSection>
      )
        : (
          <EmptyResults />
        )}
    </PageContainer>
  );
}
