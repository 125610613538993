import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './states/AuthState';

export default function PrivateRoute(props) {
  const {
    children,
    component,
    exact,
    location,
    path,
    sensitive,
    strict,
  } = props;

  const { isAuthed } = useAuth();
  return (
    <Route
      component={component}
      exact={exact}
      location={location}
      path={path}
      sensitive={sensitive}
      strict={strict}
      render={({ location: loc }) => (
        isAuthed ? children
          : (
            <Redirect
              to={{
                pathname: '/dashboard/login',
                state: { from: loc },
              }}
            />
          )
      )}
    />
  );
}

PrivateRoute.propTypes = Route.propTypes;
