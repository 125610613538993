import React from 'react';

import styled from 'styled-components';

import { Icon, Table } from 'rsuite';

import PropTypes from 'prop-types';

const { Column, HeaderCell, Cell } = Table;

const Correct = () => (
  <>
    <Icon icon="check" style={{ color: '#090' }} />
    {' '}
    Correct
  </>
);

const Incorrect = () => (
  <>
    <Icon icon="close" style={{ color: '#c30' }} />
    {' '}
    Incorrect
  </>
);

const TableContainer = styled.div`
  margin-bottom: 100px;
  max-width: 700px;
`;

export default function ResultsTable({ results }) {
  return (
    <TableContainer>
      <Table autoHeight data={results}>
        <Column flexGrow={1} align="center" fixed>
          <HeaderCell>Question No.</HeaderCell>
          <Cell dataKey="number" />
        </Column>
        <Column flexGrow={1} fixed>
          <HeaderCell>Correct / Incorrect</HeaderCell>
          <Cell>
            {(rowData) => (rowData.correct ? <Correct /> : <Incorrect />)}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center" fixed>
          <HeaderCell>Seconds Used</HeaderCell>
          <Cell dataKey="secondsUsed" />
        </Column>
      </Table>
    </TableContainer>
  );
}

ResultsTable.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    answerIds: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.bool,
    answeredAt: PropTypes.string,
    questionStartedAt: PropTypes.string,
  })).isRequired,
};
