import { Bar } from 'react-chartjs-2';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import randomColor from 'randomcolor';

const options = {
  title: {
    display: true,
    text: 'Average Response Time per Question',
  },
  legend: {
    display: false,
  },
  scales: {
    yAxes: [{
      display: true,
      scaleLabel: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        callback: (value) => `${value}s`,
      },
    }],
  },
};
export default function ResponseTimeGraph({ results }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (results && results.length) {
      const numAnswers = results[0].answers.length;
      const color = randomColor();
      setData(
        {
          labels: [...Array(numAnswers)].map((_val, index) => `Q${index + 1}`),
          datasets: [{
            label: 'Average Response Time',
            backgroundColor: color,
            borderColor: color,
            data: [...Array(numAnswers)].map(
              (_val, index) => (results.reduce(
                (acu, cur) => acu + cur.answers[index].answerTime, 0,
              ) / results.length).toFixed(2),
            ),
          }],
        },
      );
    }
  }, [results]);

  return (
    <Bar data={data} options={options} />
  );
}

ResponseTimeGraph.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          questionStartedAt: PropTypes.string.isRequired,
          answeredAt: PropTypes.string.isRequired,
          answerTime: PropTypes.number.isRequired,
          answerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
          correct: PropTypes.bool.isRequired,
        }),
      ).isRequired,
      avgScore: PropTypes.number.isRequired,
      avgTime: PropTypes.number.isRequired,
      numCorrect: PropTypes.number.isRequired,
      totalScore: PropTypes.number.isRequired,
      totalTime: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
