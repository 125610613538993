import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import QuestionMedia from '../components/QuestionMedia';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  flex-grow: 1;
`;

const QuestionText = styled.h1`
  margin-bottom: 0.5em;
  font-size: calc(20px + 2vmin);
  line-height: inherit;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 20px;
`;

export default function QuestionPage({ question }) {
  return (
    <Container>
      <QuestionContainer>
        <QuestionText>{question.question}</QuestionText>
        <QuestionMedia image={question.image} video={question.video} />
      </QuestionContainer>
    </Container>
  );
}

QuestionPage.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    image: PropTypes.string,
    video: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ),
    type: PropTypes.string.isRequired,
  }).isRequired,
};
