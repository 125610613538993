import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'rsuite';

const PageContainerElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled(Col)`
  max-width: 1400px;
  padding: 2em 2%;
`;

export default function PageContainer({ children }) {
  return (
    <PageContainerElement>
      <Content xs={24} sm={24} md={20} lg={20}>
        {children}
      </Content>
    </PageContainerElement>
  );
}

PageContainer.defaultProps = {
  children: null,
};

PageContainer.propTypes = {
  children: PropTypes.node,
};
