import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Icon } from 'rsuite';
import AnswerPage from './AnswerPage';
import QuestionPage from './QuestionPage';
import ButtonFooter from '../components/ButtonFooter';
import GreyButton from '../components/GreyButton';

export default function QuestionAnswerPage({
  question,
  handleSelect,
  areAnswersShown,
  selectedAnswers,
  correctAnswers,
  isAdmin,
  onAdvance,
}) {
  const [onAnswerPage, setOnAnswerPage] = useState(false);

  useEffect(() => {
    setOnAnswerPage(false);
  }, [question]);

  const handleSwitch = useCallback(() => {
    setOnAnswerPage((old) => !old);
  }, []);

  const getSwitchText = () => {
    let questionPageSwitchText = 'See Options';
    if (!isAdmin) {
      const plural = question.type === 'single' ? '' : 's';
      if (areAnswersShown) {
        questionPageSwitchText = `Show Answer${plural}`;
      } else {
        questionPageSwitchText = `Pick Answer${plural}`;
      }
    }
    const answerPageSwitchText = 'See Question';
    return onAnswerPage
      ? answerPageSwitchText
      : questionPageSwitchText;
  };

  return (
    <>
      {onAnswerPage ? (
        <AnswerPage
          question={question}
          selectedAnswers={selectedAnswers}
          onSelect={handleSelect}
          areAnswersShown={areAnswersShown}
          correctAnswers={correctAnswers}
        />
      ) : (
        <QuestionPage question={question} />
      )}
      <ButtonFooter>
        <GreyButton onClick={handleSwitch}>{getSwitchText()}</GreyButton>
        {isAdmin && (
        <GreyButton onClick={onAdvance}>
          Advance Quiz
          {' '}
          <Icon icon="long-arrow-right" />
        </GreyButton>
        )}
      </ButtonFooter>
    </>
  );
}

QuestionAnswerPage.defaultProps = {
  handleSelect: () => {},
  onAdvance: () => {},
  selectedAnswers: [],
  isAdmin: false,
};

QuestionAnswerPage.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    image: PropTypes.string,
    video: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ),
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleSelect: PropTypes.func,
  onAdvance: PropTypes.func,
  areAnswersShown: PropTypes.bool.isRequired,
  selectedAnswers: PropTypes.arrayOf(PropTypes.string),
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAdmin: PropTypes.bool,
};
