import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import { Panel, Grid, Col } from 'rsuite';

import { Link } from 'react-router-dom';

const PanelContainer = styled(Grid)`
  height:100%;
  display: grid;
  place-items: center;
  padding-bottom: 5vh;
`;

const PaddedLink = styled(Link)`
  display: block;
  padding: 16px 20px 0;
`;

const HeaderContainer = styled.div`
  padding-bottom: 2em;
`;

export default function AuthPanel({ header, children }) {
  return (
    <PanelContainer>
      <Col xs={24} sm={24} md={12}>
        <Panel bordered>
          <HeaderContainer>
            {header}
          </HeaderContainer>
          {children}
        </Panel>
        <PaddedLink to="/">Want to join a game instead?</PaddedLink>
      </Col>
    </PanelContainer>
  );
}

AuthPanel.defaultProps = {
  header: null,
  children: null,
};

AuthPanel.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};
