import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { Alert, Uploader, Button } from 'rsuite';

import styled from 'styled-components';

import imageCompression from 'browser-image-compression';

import BreadcrumbLink from '../components/BreadcrumbLink';
import CloseableImage from '../components/CloseableImage';
import EditQuizName from '../components/EditQuizName';
import GameLoader from '../components/GameLoader';
import PageContainer from '../components/PageContainer';
import QuestionList from '../components/QuestionList';
import StartGameButton from '../components/StartGameButton';
import StyledBreadcrumb from '../components/StyledBreadcrumb';
import TitleSubtitle from '../components/TitleSubtitle';
import { useQuizEdit } from '../states/QuizEditState';
import { startSession, getQuiz, updateQuiz } from '../utils/api';
import { NO_IMAGE } from '../utils/constants';
import { fileToDataUrl } from '../utils/helpers';

const ImageUploadContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

const Thumbnail = styled(CloseableImage)`
  height: 120px !important;
  width: 150px !important;
`;

export default function QuizEditPage() {
  const { quizId } = useParams();
  const [loading, setLoading] = useState(true);
  const { quiz, fetchQuiz, setThumbnail } = useQuizEdit();
  const history = useHistory();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const handleSubmit = useCallback(
    (newName) => updateQuiz(quizId, { ...quiz, name: newName })
      .then((json) => {
        if (json.error) {
          throw new Error();
        }
        return fetchQuiz(quizId);
      }).catch(() => {
        Alert.error('Something went wrong');
      }),
    [fetchQuiz, quiz, quizId],
  );

  useEffect(() => {
    setLoading(true);
    fetchQuiz(quizId).then((res) => {
      if (res.error) {
        history.push('/dashboard');
      } else {
        setLoading(false);
      }
    });
  }, [fetchQuiz, history, quizId]);

  useEffect(() => {
    if (quiz && quiz.name) {
      document.title = `Editing ${quiz.name} | BigBrain`;
    } else {
      document.title = 'Edit Quiz | BigBrain';
    }
  }, [quiz]);

  const handleStart = async () => {
    const quizData = await getQuiz(quizId);
    if (quizData.active) {
      history.push(`/dashboard/${quizId}/session/${quizData.active}`);
    } else {
      startSession(quizId)
        .then((res) => {
          if (res.error) {
            throw new Error();
          }
          return getQuiz(quizId);
        })
        .then((res) => {
          if (res.error) {
            throw new Error();
          }
          history.push(`/dashboard/${quizId}/session/${res.active}`);
        })
        .catch(() => {
          Alert.error('Could not start session');
        });
    }
  };

  const handleMediaChange = (fileList) => {
    const fileData = fileList.pop();
    if (fileData) {
      setUploadLoading(true);
      const compressOptions = {
        maxSizeMB: 1,
        useWebWorker: true,
        maxWidthOrHeight: 300,
      };
      imageCompression(fileData.blobFile, compressOptions)
        .then((compressedBlob) => fileToDataUrl(compressedBlob))
        .then((base64) => setThumbnail(base64))
        .then(() => {
          setUploadLoading(false);
        });
    }
  };

  const handleMediaRemove = () => {
    setImageLoading(true);
    setThumbnail(NO_IMAGE)
      .then(() => {
        setImageLoading(false);
      });
  };

  return loading ? (
    <GameLoader />
  ) : (
    <PageContainer>
      <StyledBreadcrumb>
        <BreadcrumbLink to="/dashboard">Dashboard</BreadcrumbLink>
        <BreadcrumbLink active>Quiz</BreadcrumbLink>
      </StyledBreadcrumb>
      <TitleSubtitle
        title="Quiz"
        subtitle={(
          <span data-test-target="Quiz Name">
            {quiz.name}
            <EditQuizName name={quiz.name} onSuccess={handleSubmit} />
          </span>
        )}
      />
      <QuestionList questions={quiz.questions} quizId={quizId} />
      <h3>Thumbnail</h3>
      {quiz.thumbnail && quiz.thumbnail !== NO_IMAGE && (
        <Thumbnail
          src={quiz.thumbnail}
          alt={`Current thumbnail of ${quiz.name}`}
          onClose={handleMediaRemove}
          loading={imageLoading}
          aria-label="Delete thumbnail"
        />
      )}
      <ImageUploadContainer>
        <Uploader
          id="image-input"
          accept="image/jpeg, image/png"
          fileListVisible={false}
          onChange={handleMediaChange}
          autoUpload={false}
        >
          <Button loading={uploadLoading}>
            {quiz.thumbnail && quiz.thumbnail !== NO_IMAGE ? 'Change' : 'Upload'}
          </Button>
        </Uploader>
      </ImageUploadContainer>
      <StartGameButton onClick={handleStart} />
    </PageContainer>
  );
}
