import React from 'react';

import { Modal, Button } from 'rsuite';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import FluidModal from './FluidModal';

const ModalTitle = styled(Modal.Title)`
  margin-bottom: 2em !important;
  white-space: break-spaces !important;
`;

export default function ShowResultsModal({ show, onHide, onSuccess }) {
  return (
    <FluidModal backdrop="static" show={show} onHide={onHide} size="xs">
      <Modal.Header closeButton={false}>
        <ModalTitle>Would you like to view the results?</ModalTitle>
      </Modal.Header>
      <Modal.Footer>
        <Button
          aria-label="Don't show results"
          onClick={onHide}
          appearance="subtle"
        >
          No thanks
        </Button>
        <Button
          aria-label="Show results"
          onClick={onSuccess}
          appearance="primary"
        >
          Show me
        </Button>
      </Modal.Footer>
    </FluidModal>
  );
}

ShowResultsModal.defaultProps = {
  show: false,
  onHide: () => {},
  onSuccess: () => {},
};

ShowResultsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
};
