import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Doughnut } from 'react-chartjs-2';

import randomColor from 'randomcolor';

export default function AnswerPieChart({ question, answers }) {
  const [data, setData] = useState();

  useEffect(() => {
    if (answers && answers.length) {
      const answerCounts = answers.reduce((acu, cur) => {
        const next = { ...acu };
        cur.answerIds.forEach((id) => {
          if (id in next) {
            next[id] += 1;
          } else {
            next[id] = 1;
          }
        });
        return next;
      }, {});

      const colors = ['#cdcdcd', ...question.answers.map(() => randomColor())];

      const noAnswers = answers.reduce((acu, answer) => acu + (answer.answerIds.length ? 0 : 1), 0);

      setData({
        datasets: [{
          data: [noAnswers, ...question.answers.map((answer) => answerCounts[answer.id])],
          backgroundColor: colors,
          borderColor: colors,
        }],
        labels: ['No answer', ...question.answers.map((answer) => answer.answer)],
      });
    }
  }, [answers, question]);

  const options = {
    title: {
      display: true,
      text: question.question,
    },
    legend: {
      display: false,
    },
  };

  return (
    <Doughnut
      data={data}
      options={options}
    />
  );
}

AnswerPieChart.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      answerTime: PropTypes.number.isRequired,
      answeredAt: PropTypes.string.isRequired,
      questionStartedAt: PropTypes.string.isRequired,
      correct: PropTypes.bool.isRequired,
      number: PropTypes.number.isRequired,
    }),
  ).isRequired,
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.string.isRequired,
      is_correct: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    type: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }).isRequired,
};
