import styled from 'styled-components';

export const ButtonRow = styled.div`
display: flex;
flex-flow: row wrap;
align-items: baseline;

& *:nth-child(1) {
  margin-right: 10px;
  margin-bottom: 10px;
}

`;

export default ButtonRow;
