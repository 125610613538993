import React from 'react';

import styled from 'styled-components';

import {
  Placeholder,
} from 'rsuite';

import QuizCardStructure from './QuizCardStructure';

const { Paragraph } = Placeholder;

const LoadingThumbnail = styled.div`
 min-width: 100%;
 min-height: 200px;
 background: #f2f2f5;
`;

export default function QuizCardLoading() {
  return (
    <QuizCardStructure
      thumbnail={<LoadingThumbnail />}
    >
      <Paragraph rows={3} />
    </QuizCardStructure>
  );
}
