import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Grid } from 'rsuite';

import GameLoader from '../components/GameLoader';
import ResultsHeader from '../components/ResultsHeader';
import ResultsSummary from '../components/ResultsSummary';
import ResultsTable from '../components/ResultsTable';
import { fetchPlayerResults } from '../utils/api';

const FillHeightContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const diffSeconds = (d1, d2) => (Date.parse(d1) - Date.parse(d2)) / 1000;

const secondsUsedString = (answer) => {
  if (answer.answeredAt && answer.questionStartedAt) {
    return `${diffSeconds(answer.answeredAt, answer.questionStartedAt).toFixed(2)}s`;
  }
  return '-';
};

export default function GameResultsPage() {
  const { playerId } = useParams();
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchData = useCallback(
    () => fetchPlayerResults(playerId)
      .then((json) => {
        if (json.error) {
          history.push('/');
        } else if (json) {
          const { questions, answers } = json;
          const filled = [...Array(questions)].map((_, index) => {
            const answered = answers.find((a) => a.position === index);
            return answered || {
              position: index,
              answerIds: [],
              correct: false,
              answeredAt: null,
              questionStartedAt: null,
            };
          });
          const newResults = filled?.map((answer) => ({
            ...answer,
            number: answer.position + 1,
            secondsUsed: secondsUsedString(answer),
          }));
          setResults(newResults);
          setIsLoading(false);
        }
        return json;
      }).catch(() => {
        setTimeout(() => fetchData(), 3000);
      }),
    [playerId, history],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    document.title = 'Player Results | BigBrain';
  }, []);

  const correct = (answers) => answers.filter((answer) => answer.correct);

  return (
    <FillHeightContainer>
      {isLoading || !results || !results.length ? (
        <GameLoader message="Loading results..." />
      ) : (
        <Grid align="middle">
          <ResultsHeader />
          <ResultsSummary
            numCorrect={correct(results).length}
            numTotal={results.length}
          />
          {results.length > 0 && (
          <ResultsTable results={results} />
          )}
        </Grid>
      )}
    </FillHeightContainer>
  );
}
