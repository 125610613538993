import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { getQuiz, updateQuiz } from '../utils/api';

const QuizEditContext = createContext();

export function useQuizEdit() {
  return useContext(QuizEditContext);
}

export default function QuizEditState({ children }) {
  const [quiz, setQuiz] = useState(null);

  const fetchQuiz = useCallback(
    (quizId) => getQuiz(quizId).then((data) => {
      setQuiz({ ...data, id: quizId });
      return data;
    }),
    [setQuiz],
  );

  const addQuestion = useCallback(
    (question) => {
      let newQuestion = true;
      const questions = quiz.questions.map((q) => {
        if (q.id === question.id) {
          newQuestion = false;
          return question;
        }
        return q;
      });

      if (newQuestion) {
        questions.push(question);
      }

      const nextState = {
        ...quiz,
        questions,
      };

      return updateQuiz(nextState.id, nextState).then((res) => {
        if (!res.error) {
          setQuiz(nextState);
        }
        return res;
      });
    },
    [quiz],
  );

  const removeQuestion = useCallback(
    (question) => {
      const nextState = {
        ...quiz,
        questions: quiz.questions.filter((q) => q.id !== question.id),
      };
      return updateQuiz(nextState.id, nextState).then((res) => {
        if (!res.error) {
          setQuiz(nextState);
        }
        return res;
      });
    },
    [quiz],
  );

  const setQuestions = useCallback(
    (questions) => {
      const nextState = { ...quiz, questions };
      setQuiz(nextState);
      return updateQuiz(nextState.id, nextState);
    },
    [quiz],
  );

  const setThumbnail = useCallback(
    (thumbnail) => {
      const nextState = { ...quiz, thumbnail };
      return updateQuiz(nextState.id, nextState)
        .then((res) => {
          if (res.error) {
            throw new Error();
          }
          return fetchQuiz(nextState.id);
        });
    },
    [fetchQuiz, quiz],
  );

  const providerValue = useMemo(
    () => ({
      quiz,
      setQuiz,
      addQuestion,
      fetchQuiz,
      removeQuestion,
      setQuestions,
      setThumbnail,
    }),
    [quiz, addQuestion, fetchQuiz, removeQuestion, setQuestions, setThumbnail],
  );

  return (
    <QuizEditContext.Provider value={providerValue}>
      {children}
    </QuizEditContext.Provider>
  );
}

QuizEditState.propTypes = { children: PropTypes.node.isRequired };
