import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import AnswerButton from './AnswerButton';

const ButtonGrid = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 0.5rem;

  padding: 0.5rem;
`;

export default function AnswerGrid({
  answers, selectedAnswers, onSelect, areAnswersShown, correctAnswers,
}) {
  return (
    <ButtonGrid>
      {answers.map((answer, index) => (
        <AnswerButton
          key={answer.id}
          variant={index}
          answer={answer}
          onClick={onSelect}
          isPressed={selectedAnswers.includes(answer.id)}
          showCorrect={areAnswersShown}
          isCorrect={correctAnswers.includes(answer.id)}
        />
      ))}
    </ButtonGrid>
  );
}

AnswerGrid.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      id: PropTypes.string,
      is_correct: PropTypes.bool,
    }),
  ).isRequired,
  selectedAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  areAnswersShown: PropTypes.bool.isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
