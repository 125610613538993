import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Button } from 'rsuite';

import EmptyContainer from './EmptyContainer';
import QuizCard from './QuizCard';

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  place-items: center;
  grid-gap: 1.5rem;
  padding: 1rem;
  margin-bottom: 3rem;
`;

export default function QuizCardGrid({ quizzes, onChange, onCreate }) {
  if (!quizzes || !quizzes.length) {
    return (
      <EmptyContainer>
        <div><h3>No Quizzes Yet</h3></div>
        <Button
          type="button"
          appearance="link"
          size="lg"
          onClick={onCreate}
        >
          Create One
        </Button>
      </EmptyContainer>
    );
  }

  return (
    <CardGrid>
      {quizzes.map((game) => (
        <QuizCard id={game.id} key={game.id} onChange={onChange} />
      ))}
    </CardGrid>
  );
}

QuizCardGrid.defaultProps = {
  quizzes: [],
  onChange: () => {},
  onCreate: () => {},
};

QuizCardGrid.propTypes = {
  quizzes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
};
