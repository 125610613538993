import React, { useCallback, useEffect, useState } from 'react';

import { saveAs } from 'file-saver';

import { Link, useHistory } from 'react-router-dom';

import moment from 'moment';

import { Alert, FlexboxGrid, Icon } from 'rsuite';

import PropTypes from 'prop-types';

import ShowResultsModal from '../ShowResultsModal';
import {
  endSession, getQuiz, startSession, deleteQuiz,
} from '../../utils/api';
import { NO_IMAGE } from '../../utils/constants';

import {
  QuizThumbnail,
  QuizHeader,
  QuizTitle,
  QuizFooter,
} from './QuizCardElements';
import QuizCardLoading from './QuizCardLoading';
import QuizCardOptions from './QuizCardOptions';
import QuizCardStructure from './QuizCardStructure';

const colors = [
  '#3498FF',
  '#3F51B5',
  '#2487C2',
  '#F5A623',
  '#FFEB3B',
  '#429321',
];

const getColor = () => colors[Math.floor(Math.random() * Math.floor(colors.length - 1))];

export default function QuizCard({ id, onChange }) {
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState(null);
  const [color] = useState(getColor());
  const [duration, setDuration] = useState(0);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const history = useHistory();
  const [stopModalShown, setStopModalShown] = useState(false);
  const [prevSessionId, setPrevSessionId] = useState(null);

  const fetchQuiz = useCallback(
    () => getQuiz(id).then((data) => {
      setQuizData(data);
    }),
    [id],
  );

  useEffect(() => {
    setLoading(true);
    fetchQuiz().then(() => {
      setLoading(false);
    });
  }, [fetchQuiz]);

  useEffect(() => {
    if (quizData) {
      setDuration(
        quizData.questions.reduce((sum, question) => sum + question.duration, 0),
      );
    }
  }, [quizData]);

  const handleStart = useCallback(() => {
    if (quizData.active) {
      history.push(`/dashboard/${id}/session/${quizData.active}`);
    } else {
      setOptionsLoading(true);
      startSession(id)
        .then(() => getQuiz(id))
        .then((res) => {
          setOptionsLoading(false);
          history.push(`/dashboard/${id}/session/${res.active}`);
        })
        .catch((err) => {
          Alert.error(err.message);
        });
    }
  }, [history, id, quizData]);

  const handleDelete = useCallback(() => {
    setOptionsLoading(true);
    deleteQuiz(id)
      .then(() => onChange())
      .then(() => setOptionsLoading(false));
  }, [id, onChange]);

  const handleExport = useCallback(() => {
    const output = JSON.stringify(
      {
        name: quizData.name,
        thumbnail: quizData.thumbnail,
        questions: quizData.questions,
      },
      null,
      4,
    );
    const blob = new Blob([output], { type: 'application/json;charset=utf-8' });
    const filename = `${quizData.name
      .replaceAll(/\s+/g, '_')
      .toLowerCase()}.json`;
    saveAs(blob, filename);
  }, [quizData]);

  const handleStop = useCallback(() => {
    setOptionsLoading(true);
    endSession(id)
      .then(() => setPrevSessionId(quizData.active))
      .then(() => fetchQuiz())
      .then(() => setOptionsLoading(false))
      .then(() => setStopModalShown(true))
      .catch((err) => {
        Alert.error(err.message);
      });
  }, [fetchQuiz, id, quizData]);

  const handleShowResults = useCallback(() => {
    setStopModalShown(false);
    if (prevSessionId) {
      history.push(`/dashboard/${id}/session/${prevSessionId}/results`);
    }
  }, [history, id, prevSessionId]);

  if (loading || !quizData) {
    return <QuizCardLoading />;
  }

  const thumbnail = quizData.thumbnail && quizData.thumbnail !== NO_IMAGE ? (
    <QuizThumbnail
      src={quizData.thumbnail}
      alt={`Thumbnail for ${quizData.name}`}
    />
  ) : (
    <FlexboxGrid
      justify="center"
      align="middle"
      style={{ width: '100%', height: '200px' }}
    >
      <FlexboxGrid.Item>
        <Icon icon="image" style={{ color }} size="5x" />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );

  return (
    <QuizCardStructure thumbnail={thumbnail}>
      <QuizHeader>
        <Link to={`/dashboard/edit/${id}`} aria-label="Show quiz details">
          <QuizTitle>{quizData.name}</QuizTitle>
        </Link>
        <QuizCardOptions
          loading={optionsLoading}
          onDelete={handleDelete}
          onExport={handleExport}
          onStart={handleStart}
          onStop={handleStop}
          sessionActive={!!quizData.active}
        />
      </QuizHeader>
      <QuizFooter>
        <div>{moment.duration(duration, 'seconds').humanize()}</div>
        <div>{`${quizData.questions.length} questions`}</div>
      </QuizFooter>
      <ShowResultsModal
        show={stopModalShown}
        onHide={() => setStopModalShown(false)}
        onSuccess={handleShowResults}
      />
    </QuizCardStructure>
  );
}

QuizCard.propTypes = {
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
