import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import PropTypes from 'prop-types';

const renderer = ({ minutes, seconds }) => (
  <span>
    {`${zeroPad(minutes)}:${zeroPad(seconds)}`}
  </span>
);

export default function GameCountdown({ date }) {
  return (
    <Countdown date={date} renderer={renderer} />
  );
}
GameCountdown.propTypes = {
  date: PropTypes.number.isRequired,
};
