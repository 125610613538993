import styled from 'styled-components';

export const QuizThumbnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

export const QuizHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 1.2em;
`;

export const QuizTitle = styled.h2`
  color: #575757;
  font-size: 18px;
  line-height: 24px;
`;

export const QuizFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5em;
  text-transform: capitalize;
`;
