import React from 'react';

import {
  Alert, Navbar, Tooltip, Whisper,
} from 'rsuite';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import copy from 'copy-to-clipboard';

import GameCountdown from './GameCountdown';

const AppBar = styled(Navbar)`
  max-height: 56px;

  font-weight: bold;
  font-size: 1.5em;
  padding: 10px 8px;
  white-space: nowrap;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &:before {
    display: none;
  }

  & div {
    &:first-child() {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`;

const Pin = styled.button`
  background: none;
`;

const CopyToolTip = <Tooltip>Click to copy pin.</Tooltip>;

export default function GameAppBar({ type, sessionId, countdownDate }) {
  const handleCopy = () => {
    if (copy(sessionId)) {
      Alert.success('Copied link to clipboard.', 1000);
    }
  };

  return (
    <AppBar>
      <div>{type}</div>
      <Whisper placement="bottom" trigger="hover" speaker={CopyToolTip}>
        <Pin
          type="button"
          aria-label="Copy pin"
          onClick={handleCopy}
        >
          {`Pin: ${sessionId}`}
        </Pin>
      </Whisper>
      <div>
        {countdownDate && countdownDate >= Date.now() && (
          <GameCountdown date={countdownDate} />
        )}
      </div>
    </AppBar>
  );
}

GameAppBar.defaultProps = {
  type: '',
  countdownDate: null,
};

GameAppBar.propTypes = {
  sessionId: PropTypes.string.isRequired,
  countdownDate: PropTypes.number,
  type: PropTypes.string,
};
