import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Slider, InputNumber,
} from 'rsuite';

import styled from 'styled-components';

import AnswerPieChart from './AnswerPieChart';

const Container = styled.div`
  width: 100%;
`;

const SliderPadding = styled.div`
  padding: 0 10px;
  flex-grow: 1;
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5em;
`;

const SliderNumberInput = styled(InputNumber)`
  max-width: 3em;
`;

export default function QuizAnswerPieCharts({ quiz, results }) {
  const [questionNumber, setQuestionNumber] = useState(1);

  const updateQuestionNumber = (value) => {
    if ((value - 1) in quiz?.questions) {
      setQuestionNumber(value);
    }
  };

  if (!quiz?.questions?.length || !results) {
    return null;
  }

  return (
    <Container>
      <AnswerPieChart
        question={quiz.questions[questionNumber - 1]}
        answers={results.reduce((acu, cur) => [...acu, cur.answers[questionNumber - 1]], [])}
      />
      <SliderContainer>
        <SliderPadding>
          <Slider
            progress
            graduated
            defaultValue={0}
            onChange={updateQuestionNumber}
            value={questionNumber}
            min={1}
            max={quiz.questions.length}
          />
        </SliderPadding>
        <SliderNumberInput
          min={1}
          max={quiz.questions.length}
          value={questionNumber}
          onChange={updateQuestionNumber}
        />
      </SliderContainer>
    </Container>
  );
}

QuizAnswerPieCharts.propTypes = {
  quiz: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      video: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(PropTypes.shape({
        answer: PropTypes.string.isRequired,
        is_correct: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })),
      type: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
    })),
  }).isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          questionStartedAt: PropTypes.string.isRequired,
          answeredAt: PropTypes.string.isRequired,
          answerTime: PropTypes.number.isRequired,
          answerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
          correct: PropTypes.bool.isRequired,
        }),
      ).isRequired,
      avgScore: PropTypes.number.isRequired,
      avgTime: PropTypes.number.isRequired,
      numCorrect: PropTypes.number.isRequired,
      totalScore: PropTypes.number.isRequired,
      totalTime: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
