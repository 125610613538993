import React from 'react';

import ReactPlayer from 'react-player/youtube';

import styled from 'styled-components';

import PropTypes from 'prop-types';

const QuestionImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const MediaContainer = styled.div`
  /* max-width: 700px; */
  width: 90%;
  height: 80%;
  max-height: 40%;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export default function QuestionMedia({ image, video }) {
  if (!(image || video)) {
    return null;
  }

  return (
    <MediaContainer>
      {video && (
        <VideoContainer>
          <Player url={video} width="100%" height="100%" pip={false} />
        </VideoContainer>
      )}
      {image && !video && <QuestionImage src={image} />}
    </MediaContainer>
  );
}

QuestionMedia.defaultProps = {
  image: null,
  video: null,
};

QuestionMedia.propTypes = {
  image: PropTypes.string,
  video: PropTypes.string,
};
