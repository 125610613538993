import React, { useEffect, useCallback, useState } from 'react';

import { Redirect, useParams, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { Alert } from 'rsuite';

import { useGameState, GAME_STATES } from '../GameState';
import GameAppBar from '../components/GameAppBar';
import GameLoader from '../components/GameLoader';
import { sendPlayerAnswer } from '../utils/api';
import socket from '../socket';

import QuestionAnswerPage from './QuestionAnswerPage';

const FillHeightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export default function GamePage() {
  const { sessionid } = useParams();
  const { playerId } = useGameState();
  const {
    stateCode, question, setCorrectAnswers, fetchAnswer, fetchQuestion,
  } = useGameState();
  const [isLoading, setIsLoading] = useState(true);
  const [countdownDate, setCountdownDate] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const { correctAnswers } = useGameState();
  const areAnswersShown = stateCode === GAME_STATES.DISPLAY_QUESTION_RESULT;
  const history = useHistory();

  const sendAnswer = useCallback(() => {
    const answersToBeSent = [...selectedAnswers];
    if (!answersToBeSent.length) {
      return null;
    }

    return sendPlayerAnswer(playerId, answersToBeSent)
      .then((json) => {
        if (json.error) {
          throw new Error();
        }
        return json;
      })
      .catch(() => {
        Alert.error('Answer could not be sent');
        Alert.closeAll();
      });
  }, [playerId, selectedAnswers]);

  const selectAnswer = useCallback(
    (answerId) => {
      const isSingle = question.type === 'single';
      if (isSingle) {
        setSelectedAnswers([answerId]);
      } else {
        setSelectedAnswers((oldSelected) => [
          ...new Set([...oldSelected, answerId]),
        ]);
      }
      return true;
    },
    [question],
  );

  const deselectAnswer = useCallback((answerId) => {
    setSelectedAnswers((oldSelected) => oldSelected.filter((id) => id !== answerId));
    return false;
  }, []);

  const handleSelect = useCallback(
    (answerId) => {
      if (stateCode === GAME_STATES.DISPLAY_QUESTION_RESULT) {
        return;
      }

      if (selectedAnswers.includes(answerId)) {
        deselectAnswer(answerId);
      }
      selectAnswer(answerId);
    },
    [deselectAnswer, selectAnswer, selectedAnswers, stateCode],
  );

  useEffect(() => {
    setSelectedAnswers([]);
  }, [correctAnswers]);

  useEffect(() => {
    if (selectedAnswers.length) {
      sendAnswer();
    }
  }, [selectedAnswers, sendAnswer]);

  const renderBasedOnGameState = useCallback(() => {
    switch (stateCode) {
      case GAME_STATES.NOT_IN_GAME:
        return <Redirect to="/" />;
      case GAME_STATES.WAITING_TO_START:
        document.title = 'Waiting... | BigBrain';
        return <GameLoader message="Waiting for game to begin..." />;
      case GAME_STATES.ANSWERING_QUESTION:
      case GAME_STATES.DISPLAY_QUESTION_RESULT:
        document.title = 'In Session | BigBrain';
        return (
          <QuestionAnswerPage
            question={question}
            handleSelect={handleSelect}
            areAnswersShown={areAnswersShown}
            selectedAnswers={selectedAnswers}
            correctAnswers={correctAnswers}
          />
        );
      case GAME_STATES.DISPLAY_GAME_RESULTS:
        return <Redirect to={`/game/${sessionid}/results/${playerId}`} />;
      default:
        return null;
    }
  }, [
    areAnswersShown,
    correctAnswers,
    handleSelect,
    playerId,
    question,
    selectedAnswers,
    sessionid,
    stateCode,
  ]);

  const questionType = useCallback(() => {
    if (!question) {
      return null;
    }
    return question.type === 'single' ? 'Single' : 'Multiple';
  }, [question]);

  useEffect(() => {
    setCorrectAnswers([]);
  }, [setCorrectAnswers]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchQuestion(), fetchAnswer()]).then(() => {
      setIsLoading(false);
    });
  }, [fetchAnswer, fetchQuestion]);

  useEffect(() => {
    if (question) {
      setCountdownDate(
        Date.parse(question.isoTimeLastQuestionStarted)
          + question.duration * 1000,
      );
    }
  }, [question]);

  useEffect(() => {
    socket.emit('joinSession', { sessionId: sessionid });

    return () => {
      socket.emit('leaveSession', { sessionId: sessionid });
    };
  }, [sessionid]);

  useEffect(() => {
    const listener = () => {
      fetchQuestion();
    };
    socket.on('sessionNewQuestion', listener);
    return () => {
      socket.off('sessionNewQuestion', listener);
    };
  }, [fetchQuestion]);

  useEffect(() => {
    const listener = () => {
      fetchAnswer();
    };
    socket.on('sessionAnswersAvailable', listener);
    return () => {
      socket.off('sessionAnswersAvailable', listener);
    };
  }, [fetchAnswer]);

  useEffect(() => {
    const listener = () => {
      history.push(`/game/${sessionid}/results/${playerId}`);
    };
    socket.on('sessionEnded', listener);
    return () => {
      socket.off('sessionEnded', listener);
    };
  }, [history, playerId, sessionid]);

  return (
    <FillHeightContainer>
      {isLoading && !playerId ? (
        <GameLoader />
      ) : (
        <>
          <GameAppBar
            type={questionType()}
            sessionId={sessionid}
            countdownDate={countdownDate}
          />
          {renderBasedOnGameState()}
        </>
      )}
    </FillHeightContainer>
  );
}
