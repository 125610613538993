import { Button } from 'rsuite';
import styled from 'styled-components';

// Override rsuite styles
const GreyButton = styled(Button)`
  color: #fff !important;
  background: #595959 !important;
  &:hover {
    background: #4F4F4F !important;
  }
`;

export default GreyButton;
