import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

const SummaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  margin: 10vh 0;
`;

const SummaryStat = styled.h1`
  font-size: 5em;
`;

const SummarySmall = styled.span`
  font-size: 0.5em;
`;

export default function ResultsSummary({ numCorrect, numTotal }) {
  return (
    <SummaryContainer>
      <SummaryStat>
        {numCorrect}
        <SummarySmall>{` of ${numTotal}`}</SummarySmall>
      </SummaryStat>
      <h2>Questions Correct</h2>
    </SummaryContainer>
  );
}

ResultsSummary.propTypes = {
  numCorrect: PropTypes.number.isRequired,
  numTotal: PropTypes.number.isRequired,
};
