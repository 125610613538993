import React from 'react';
import { Panel, IconButton, Icon } from 'rsuite';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainPanel = styled(Panel)`
  height: 50px;
  margin-top: 10px;

  & .rs-panel-body {
    width: 100%;
  }
`;

const PanelBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  height: 50px;
  width: 50px;
  object-fit: cover;
`;

const Name = styled.div`
  padding: 5px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 5px;
`;

export default function FileListItem({ thumbnail, name, onClose }) {
  return (
    <MainPanel bordered bodyFill>
      <PanelBody>
        <Thumbnail src={thumbnail} alt={name} height="50" width="50" />
        <Name>{name}</Name>
        <CloseButton
          icon={<Icon icon="close" />}
          appearance="subtle"
          circle
          size="sm"
          onClick={onClose}
        />
      </PanelBody>
    </MainPanel>
  );
}

FileListItem.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
