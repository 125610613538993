import React, { useCallback, useEffect, useState } from 'react';

import { Redirect, useParams } from 'react-router-dom';

import { Alert } from 'rsuite';

import socket from '../socket';
import GameAppBar from '../components/GameAppBar';
import GameLoader from '../components/GameLoader';
import { fetchSessionStatus, advanceSession } from '../utils/api';
import { questionType } from '../utils/helpers';

import QuestionAnswerPage from './QuestionAnswerPage';
import SessionWaitingPage from './SessionWaitingPage';

export default function SessionPage() {
  const { quizId, sessionId } = useParams();
  const [status, setStatus] = useState(null);
  const [answersShown, setAnswersShown] = useState(false);
  const [countdownDate, setCountdownDate] = useState(null);

  const fetchData = useCallback(
    () => fetchSessionStatus(sessionId)
      .then((json) => {
        if (json.error) {
          Alert.error(json.error);
        } else {
          setStatus(json.results);
        }
      })
      .catch(() => {}),
    [sessionId],
  );

  const handleAdvance = useCallback(
    () => advanceSession(quizId)
      .then((res) => {
        if (res.json) {
          throw new Error();
        }
        return fetchData();
      })
      .catch(() => {
        if (status.position === -1) {
          Alert.error('Could not start game');
        } else {
          Alert.error('Could not advance session');
        }
      }),
    [fetchData, quizId, status],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    socket.on('playerJoinedSession', (name) => {
      setStatus((oldStatus) => (oldStatus
        ? { ...oldStatus, players: [...oldStatus.players, name] }
        : oldStatus));
    });
  }, []);

  useEffect(() => {
    let timeout = null;
    if (status && status.active && status.position !== -1) {
      if (status.answerAvailable) {
        setAnswersShown(true);
      } else {
        setAnswersShown(false);

        const questionDuration = status.questions[status.position].duration;
        const timeStart = Date.parse(status.isoTimeLastQuestionStarted);
        const timeNow = Date.now();
        const timeUntil = timeStart + questionDuration * 1000 - timeNow;

        if (timeUntil <= 0) {
          setAnswersShown(true);
        } else {
          timeout = setTimeout(() => {
            setAnswersShown(true);
          }, timeUntil);
        }
      }
    }
    return () => {
      clearInterval(timeout);
    };
  }, [status]);

  useEffect(() => {
    if (
      status
      && status.position !== -1
      && status.active
      && status.isoTimeLastQuestionStarted
    ) {
      const question = status.questions[status.position];
      setCountdownDate(
        Date.parse(status.isoTimeLastQuestionStarted) + question.duration * 1000,
      );
    }
  }, [status]);

  useEffect(() => {
    if (!status) {
      document.title = 'Loading | BigBrain';
    } else if (status.position === -1) {
      document.title = 'Waiting Room | BigBrain';
    } else {
      document.title = 'Session | BigBrain';
    }
  }, [status]);

  if (!status) {
    return <GameLoader />;
  }

  if (!status.active) {
    return (
      <Redirect to={`/dashboard/${quizId}/session/${sessionId}/results`} />
    );
  }

  // Waiting for players to join
  if (status.position === -1) {
    return (
      <SessionWaitingPage
        sessionId={sessionId}
        players={status.players}
        onStart={handleAdvance}
      />
    );
  }

  const question = status.questions[status.position];
  const correctAnswers = question.answers
    .filter((answer) => answer.is_correct)
    .map((answer) => answer.id);

  return (
    <>
      <GameAppBar
        leftComponent={questionType(question)}
        sessionId={sessionId}
        countdownDate={countdownDate}
      />
      <QuestionAnswerPage
        question={question}
        areAnswersShown={answersShown}
        correctAnswers={correctAnswers}
        onAdvance={handleAdvance}
        isAdmin
      />
    </>
  );
}
