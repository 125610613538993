import React from 'react';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'rsuite';

import PropTypes from 'prop-types';

export default function BreadcrumbLink({ to, active, children }) {
  return (
    <Breadcrumb.Item active={active}>
      {active ? children : <Link to={to}>{children}</Link>}
    </Breadcrumb.Item>
  );
}

BreadcrumbLink.defaultProps = {
  to: (location) => location,
  active: false,
  children: null,
};

BreadcrumbLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  active: PropTypes.bool,
  children: PropTypes.node,
};
