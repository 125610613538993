import { Link } from 'react-router-dom';

import { Button, FormControl } from 'rsuite';

import styled from 'styled-components';

export const CentredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:100vh;
  padding-bottom: 5vh;
`;

export const Title = styled.h1`
  margin-bottom: 0.5em;
`;

export const JoinControl = styled(FormControl)`
  display: block;
  font-weight: bold;
  font-size:1.5em;
  text-align: center;
`;

export const JoinButton = styled(Button)`
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 1em;
`;

export const CreateLink = styled(Link)`
  margin: 16px;
`;
