import React from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import AnswerGrid from '../components/AnswerGrid';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height:100%;
  width:100%;
  overflow: hidden;
`;
const AnswerContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
`;

export default function AnswerPage({
  question, selectedAnswers, onSelect, areAnswersShown, correctAnswers,
}) {
  return (
    <Container>
      <AnswerContainer>
        <AnswerGrid
          answers={question.answers}
          selectedAnswers={selectedAnswers}
          onSelect={onSelect}
          areAnswersShown={areAnswersShown}
          correctAnswers={correctAnswers}
        />
      </AnswerContainer>
    </Container>
  );
}

AnswerPage.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    image: PropTypes.string,
    video: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    type: PropTypes.string.isRequired,
  }).isRequired,
  selectedAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  areAnswersShown: PropTypes.bool.isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
