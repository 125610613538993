import React, { useState } from 'react';

import {
  IconButton,
  Icon,
  Form,
  FormGroup,
  Modal,
  FormControl,
  Button,
  Schema,
  ControlLabel,
} from 'rsuite';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired('Please enter an title.'),
});

const EditButton = styled(IconButton)`
  margin-left: 1em;
`;

export default function EditQuizName({ name, onSuccess }) {
  const [visible, setVisible] = useState(false);
  const [form, setFormRef] = useState(null);
  const [formValue, setFormValue] = useState({ name });
  const [loading, setLoading] = useState(false);

  const toggleVisible = () => {
    setVisible((old) => !old);
  };

  const handleSubmit = () => {
    if (!form.check()) {
      return;
    }
    setLoading(true);
    onSuccess(formValue.name.trim()).then(() => {
      setLoading(false);
      setVisible(false);
    });
  };

  return (
    <>
      <EditButton
        aria-label="Edit quiz name"
        size="xs"
        icon={(
          <Icon
            icon="edit"
            onClick={() => setVisible(true)}
          />
        )}
      />
      <Modal
        show={visible}
        onHide={toggleVisible}
        size="xs"
      >
        <Modal.Title>What do you want to call it?</Modal.Title>
        <Modal.Body>
          <Form
            model={model}
            checkTrigger="blur"
            formValue={formValue}
            onChange={(value) => setFormValue(value)}
            onSubmit={handleSubmit}
            ref={(ref) => setFormRef(ref)}
          >
            <FormGroup controlId="name-input">
              <ControlLabel srOnly>Title</ControlLabel>
              <FormControl id="name-input" name="name" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={toggleVisible}
            disabled={loading}
          >
            Never mind
          </Button>
          <Button
            type="submit"
            appearance="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EditQuizName.defaultProps = {
  name: '',
  onSuccess: () => {},
};

EditQuizName.propTypes = {
  name: PropTypes.string,
  onSuccess: PropTypes.func,
};
