import React, {
  useEffect,
  useState,
} from 'react';

import { Table } from 'rsuite';

import styled from 'styled-components';

import PropTypes from 'prop-types';

const { Column, HeaderCell, Cell } = Table;

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 2em;
`;

export default function TopPlayersTable({ results, numPlayers }) {
  const [sortInfo, setSortInfo] = useState({ sortColumn: 'totalScore', sortType: 'desc' });
  const [tableLoading, setTableLoading] = useState(false);
  const [localResults, setLocalResults] = useState([]);

  function compare(sortColumn, sortType) {
    return (a, b) => (sortType === 'asc' ? 1 : -1) * (a[sortColumn] - b[sortColumn]);
  }

  const handleSort = (sortColumn, sortType) => {
    setTableLoading(true);
    setTimeout(() => {
      setSortInfo({
        sortColumn,
        sortType,
      });
      setTableLoading(false);
    }, 200);
  };

  function getResults() {
    return (localResults
      ? localResults.sort(compare(sortInfo.sortColumn, sortInfo.sortType))
      : localResults).slice(0, numPlayers);
  }

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  return (
    <TableContainer>
      <Table
        autoHeight
        data={getResults()}
        sortColumn={sortInfo.sortColumn}
        sortType={sortInfo.sortType}
        onSortColumn={handleSort}
        loading={tableLoading}
      >
        <Column flexGrow={1} align="center" fixed>
          <HeaderCell>Player</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column flexGrow={1} align="center" fixed sortable>
          <HeaderCell>Total Score</HeaderCell>
          <Cell dataKey="totalScore" />
        </Column>
        <Column flexGrow={1} align="center" fixed sortable>
          <HeaderCell>Mean Score</HeaderCell>
          <Cell dataKey="avgScore" />
        </Column>
        <Column flexGrow={1} align="center" fixed sortable>
          <HeaderCell>Mean Time</HeaderCell>
          <Cell dataKey="avgTime">{(rowData) => `${rowData.avgTime.toFixed(2)}s`}</Cell>
        </Column>
        <Column flexGrow={1} align="center" fixed sortable>
          <HeaderCell>No. Correct</HeaderCell>
          <Cell dataKey="numCorrect" />
        </Column>
      </Table>
    </TableContainer>
  );
}

TopPlayersTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          questionStartedAt: PropTypes.string.isRequired,
          answeredAt: PropTypes.string.isRequired,
          answerTime: PropTypes.number.isRequired,
          answerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
          correct: PropTypes.bool.isRequired,
        }),
      ).isRequired,
      avgScore: PropTypes.number.isRequired,
      avgTime: PropTypes.number.isRequired,
      numCorrect: PropTypes.number.isRequired,
      totalScore: PropTypes.number.isRequired,
      totalTime: PropTypes.number.isRequired,
    }),
  ).isRequired,
  numPlayers: PropTypes.number.isRequired,
};
