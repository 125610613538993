import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Alert, Icon, IconButton } from 'rsuite';

import GameLoader from '../components/GameLoader';
import NewGameModal from '../components/NewGameModal';
import PageContainer from '../components/PageContainer';
import QuizCardGrid from '../components/QuizCardGrid';
import { getQuizzes } from '../utils/api';

export default function DashboardPage() {
  const [allQuizzes, setAllQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const fetchQuizzes = useCallback(
    () => getQuizzes()
      .then((data) => {
        if (data.error) {
          Alert.error(data.error);
        } else {
          setAllQuizzes(data.quizzes);
        }
      })
      .catch(() => {
        Alert.error('Unable to connect to our servers.', 5000);
      }),
    [],
  );

  useEffect(() => {
    setLoading(true);
    fetchQuizzes().then(() => setLoading(false));
  }, [fetchQuizzes, location]);

  useEffect(() => {
    document.title = 'Dashboard | BigBrain';
  }, []);

  const toggleShow = () => {
    if (showModal) {
      setShowModal(false);
    } else setShowModal(true);
  };

  if (loading || !allQuizzes) {
    return <GameLoader />;
  }

  return (
    <PageContainer>
      <QuizCardGrid
        onCreate={toggleShow}
        quizzes={allQuizzes}
        onChange={fetchQuizzes}
      />
      <IconButton
        aria-label="New Game"
        onClick={toggleShow}
        size="lg"
        style={{ position: 'fixed', bottom: '10px', right: '10px' }}
        icon={<Icon icon="plus" />}
        placement="left"
        appearance="primary"
      >
        New Game
      </IconButton>
      <NewGameModal
        show={showModal}
        onHide={toggleShow}
        onCreate={fetchQuizzes}
      />
    </PageContainer>
  );
}
