import styled from 'styled-components';

const ButtonFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.5rem 1rem;

  & > * {
    width: 100%;
    max-width: 300px;
    margin: 0 0.25em;
  }
`;

export default ButtonFooter;
